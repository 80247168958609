import { useInjectDebtorDialog } from '@/components/dialog/dialogs/debtor/useInjectDebtorDialog';
import { useLocalI18n } from '@/hooks/useLocalI18n';
import {
  computed, onBeforeUnmount, onMounted, ref, watch,
} from 'vue';
import { ITab } from '@/components/tabs/useTabs';
import {
  IDialog,
  IDialogComponent,
  ModelPayload,
  useDialog,
  useDialogRouteParam,
} from '@/hooks/useDialog';
import { useProtectedInject } from '@/hooks/useProtectedInject';
import { DebtorIsOrganizationKey } from '@/symbols';
import { useProtectedDefaultCompany } from '@/hooks/useProtectedDefaultCompany';
import { useDocumentsApi } from '@/hooks/useDocumentsApi';
import {
  Bailiff, CreateWritoeModel, DebtorMainProfileOrganization, IFNS, useDebtors,
} from '@/hooks/useDebtors';
import { IToastLevel, useToast } from '@/hooks/useToast';
import { SignalType, useSignal } from '@/hooks/useSignal';
import { getRandomString } from '@/utils/string';
import { SourceErrors, useErrors } from '@/hooks/useErrors';
import {
  ActionType,
  ActiveTableAction, ActiveTableColumn,
  ActiveTableColumnFormat,
  useActiveTable,
} from '@/components/activeTable/useActiveTable';
import { openAuthProtectedFile } from '@/utils/fileUrl';
import { ActiveFormField, ActiveFormFieldType } from '@/hooks/useActiveForm';
import {
  DebtorBankAccount,
  useBankAccounts,
} from '@/components/dialog/dialogs/debtor/tabs/executions/useBankAccounts';
import { ITextInputType } from '@/components/textInput/useTextInput';
import { fsspColumns } from './fsspColumns';
import { fsspRequestsColumns } from './fsspReqColumns';
import { useDictionary } from '@/components/dialog/dialogs/debtor/tabs/executions/dictionary';
import {
  FsspHistoryItem,
  useFetchFsspRequestsHistory,
} from '@/components/dialog/dialogs/debtor/tabs/executions/useFetchFsspRequestsHistory';
import { useExpandMixin } from '@/components/activeTable/activeTableRow/expand-mixin';
import { dateInterval } from '@/utils/dateFns';
import {
  FsspArticle,
  useFsspArticles,
} from '@/components/dialog/dialogs/debtor/tabs/executions/useFsspArticles';
import { dateToApiDate } from '@/utils/date';
import { commonLegacyApiRequest } from '@core/service/commonService';
import { ApiCommand } from '@/store/modules/api';

type ActiveTabKey = 'fssp'|'fssp-requests'|'accounts'|'fns';

export const useDebtorExecutionsTab = () => {
  const { debtor } = useInjectDebtorDialog();
  const { t } = useLocalI18n('debtor.executions');
  const isLoading = ref<boolean>(false);

  const hasTreasuryInfo = computed(
    () => debtor.value.debtor_main_profile?.organization?.treasury_department_address || debtor.value.debtor_main_profile?.organization?.treasury_department_name,
  );

  const tabs = computed<Array<ITab<ActiveTabKey>>>(() => ([
    {
      key: 'fssp',
      id: 'modal_debtor_executions_tab_fssp_tab',
      label: 'Данные с ФССП',
    },
    {
      key: 'fssp-requests',
      id: 'modal_debtor_executions_tab_fssp_requests_tab',
      label: 'Обращения в ФССП',
    },
    {
      key: 'accounts',
      id: 'modal_debtor_executions_tab_accounts_tab',
      label: t('tabs.accounts'),
    },
    {
      key: 'fns',
      id: 'modal_debtor_executions_tab_fns_tab',
      label: t('tabs.fns'),
    },
  ]));

  const [activeTab] = useDialogRouteParam<ActiveTabKey>(
    'executions-tab',
    'fssp',
    ref(true),
  );

  const isOrganization = useProtectedInject(
    DebtorIsOrganizationKey,
  );

  const {
    companyId,
  } = useProtectedDefaultCompany();

  const { fetchFnsHistory } = useDocumentsApi();
  const { fetchFsspArticles } = useFsspArticles();

  const {
    createWritoe,
  } = useDebtors();

  const {
    fetchWritsOfExecution,
  } = useDocumentsApi();

  const {
    showDialog,
  } = useDialog();

  const { showToast } = useToast();

  const {
    dispatchSignal,
    subscribeToSignal,
  } = useSignal();

  const signalId = getRandomString();

  const {
    errorsMap,
    clearErrors,
    setErrors,
  } = useErrors<keyof CreateWritoeModel>();

  const {
    errorsMap: bankAccErrorsMap,
    clearErrors: clearBankAccErrors,
    setErrors: setBankAccErrors,
  } = useErrors<keyof DebtorBankAccount>();

  watch(errorsMap, async (map) => {
    await dispatchSignal(SignalType.modelErrors, { id: signalId, errors: map });
  }, {
    immediate: true,
  });

  watch(bankAccErrorsMap, async (map) => {
    await dispatchSignal(SignalType.modelErrors, { id: SignalType.bankAccModelCreated, errors: map });
  }, {
    immediate: true,
  });

  let unsubCreateTypeDialog: (() => void) | null = null;
  onBeforeUnmount(() => {
    unsubCreateTypeDialog?.();
  });

  const { getDictionary, getDictionaryItem } = useDictionary();

  const { fetchWritoeFsspHistory } = useFetchFsspRequestsHistory();
  const { setupRecordExpandRow } = useExpandMixin();

  const fsspArticlesPromise = fetchFsspArticles();

  onMounted(async () => {
    await fsspArticlesPromise;
  });

  let fsspArticles: null | FsspArticle[];

  // @ts-ignore
  const {
    records,
    columns,
    actions,
    limit,
    page,
    total,
    filters,
    filtersModel,
    fetchData: refetchFssp,
  } = useActiveTable<any & {id: 'id'}, any, 'id'>({

    name: 'debtor-executions', // aka справочник исполнительных производств
    keyField: 'id',
    isRowExpandable: true,
    defaultLimit: ref(15),

    async fetch({ params, signal }) {
      if (!debtor.value) {
        return {
          count: 0,
          results: [],
        };
      }
      isLoading.value = true;

      const results = await Promise.all([
        getDictionary(),
        fsspArticlesPromise,
      ]);
      fsspArticles = results[1];

      function formatFsspArticle(name: string) {
        return fsspArticles?.find((a) => a.name === name)?.description ?? name;
      }

      const debtor_ids = [params.filters!.id];
      // @ts-ignore
      const { debt_nature } = params.filters;

      if (activeTab.value === 'fssp') {
        const { response } = await fetchWritsOfExecution({
          ...params,
          filters: { debtor_ids, debt_nature, company_id: companyId.value },
          signal,
        });
        await Promise.all(response.results?.map(async (item) => {
          item.action = await getDictionaryItem(item.action) ?? item.action;
          item.case_number = item.case_number ?? item.number;
          item.case_date = item.case_date ?? item.date;
          item.debt_period = dateInterval(item);
          item.termination_ground_description = formatFsspArticle(item.termination_ground);
          item.send_to_fssp_date = debtor.value.debtor_main_profile.send_to_fssp_date;
          item.fssp = debtor.value.debtor_main_profile?.bailiff?.name;
          item.ls = debtor.value.debtor.personal_account;
        }));
        isLoading.value = false;
        return response;
      }

      if (activeTab.value === 'fssp-requests') {
        isLoading.value = true;
        const { response } = await fetchWritsOfExecution({
          ...params,
          filters: { debtor_ids, debt_nature, company_id: companyId.value },
          signal,
        });
        let counter = 1;
        const results = await Promise.all(response.results?.map(async (item) => {
          const hist = await fetchWritoeFsspHistory(item.id);
          hist.forEach((item: FsspHistoryItem) => {
            item.ls = debtor.value?.debtor?.personal_account;
            item.uchastok = debtor.value?.debtor_main_profile?.bailiff?.name;
            item.request_type = item.request_type === 'epgu' ? 'ЕПГУ' : 'СМЭВ';
            item.counter = counter++;
          });
          return hist;
        }));
        isLoading.value = false;
        const result = results.reduce((acc, item) => [...acc, ...item], []);
        return {
          results: result,
          count: result.length,
        };
      }

      const { response } = await fetchFnsHistory({
        ...params,
        filters: {
          debtor_ids,
          signal,
        },
      });

      isLoading.value = false;
      return response;
    },

    actions: computed(() => (([
      activeTab.value === 'fns' && {
        key: 'preview',
        icon: 'eye',
        types: [ActionType.record],
        handler: async ({ selectedItems: [id] }: any) => {
          const item = records.value.find((item) => item.id === id);
          if (!item) {
            return;
          }
          openAuthProtectedFile(item.file, false);
        },
      },
      activeTab.value === 'fns' && {
        key: 'download',
        icon: 'download',
        types: [ActionType.record],
        handler: async ({ selectedItems: [id] }: any) => {
          const item = records.value.find((item) => item.id === id);
          if (!item) {
            return;
          }
          openAuthProtectedFile(item.file, true);
        },
      },
      activeTab.value === 'fssp' && {
        icon: 'info',
        key: 'fsspRequisites',
        label: t('actions.fsspRequisites'),
        types: [ActionType.side],
        async handler() {
          const dialogFields: Array<ActiveFormField<any>> = [
            {
              key: 'name',
              type: ActiveFormFieldType.input,
              options: {},
            },
            {
              key: 'address',
              type: ActiveFormFieldType.input,
              options: { type: ITextInputType.textarea },
            },
          ].map((field) => ({
            ...field,
            field: field.key,
            options: {
              ...field.options,
              label: t(`fsspRequisites.${field.key}`),
              placeholder: t(`fsspRequisites.${field.key}`),
            },
            type: ActiveFormFieldType.input,
            defaultValue: debtor.value?.debtor_main_profile?.bailiff?.[
              field.key as keyof Bailiff
            ] as string,
          }));
          const payload: ModelPayload = {
            isEditable: false,
            withEditing: false,
            fields: dialogFields,
            mKey: 'fssp_requisites',
          };
          const dialog: IDialog = {
            component: IDialogComponent.model,
            addInRoute: false,
            ionDialogClassList: ['debtorAddExecutionModal'],
            payload,
          };
          await showDialog(dialog);
        },
      },
      activeTab.value === 'fssp' && isOrganization.value && hasTreasuryInfo.value && {
        icon: 'info',
        key: 'treasuryRequisites',
        label: t('actions.treasuryRequisites'),
        types: [ActionType.side],
        async handler() {
          await showDialog({
            component: IDialogComponent.model,
            addInRoute: false,
            ionDialogClassList: ['debtorAddExecutionModal'],
            payload: {
              mKey: 'add_treasury_requisites',
              isEditable: false,
              withEditing: false,
              fields: [
                { key: 'treasury_department_name' },
                { key: 'treasury_department_address' },
              ].map((field) => ({
                ...field,
                field: field.key,
                options: {
                  label: t(`treasuryRequisites.${field.key}`),
                  placeholder: t(`treasuryRequisites.${field.key}`),
                },
                type: ActiveFormFieldType.input,
                defaultValue: debtor.value?.debtor_main_profile?.organization?.[field.key as keyof DebtorMainProfileOrganization] as string,
              })),
            },
          });
        },
      },
      activeTab.value === 'fns' && {
        icon: 'info',
        key: 'fnsRequisites',
        label: t('actions.fnsRequisites'),
        types: [ActionType.side],
        async handler() {
          const dialogFields: Array<ActiveFormField<any>> = [
            { key: 'code' },
            { key: 'name' },
            { key: 'address' },
            { key: 'inn' },
            { key: 'kpp' },
            { key: 'payee' },
            { key: 'bank' },
            { key: 'bik' },
            { key: 'rs' },
            { key: 'ks' },
            { key: 'reg_code' },
            { key: 'phone' },
            { key: 'description' },
          ].map((field) => ({
            ...field,
            field: field.key,
            options: {
              label: t(`fnsRequisites.${field.key}`),
              placeholder: t(`fnsRequisites.${field.key}`),
            },
            type: ActiveFormFieldType.input,
            defaultValue: debtor.value?.debtor_main_profile?.ifns?.[
              field.key as keyof IFNS
            ] as string,
          }));
          await showDialog({
            component: IDialogComponent.model,
            addInRoute: false,
            ionDialogClassList: ['debtorAddExecutionModal'],
            payload: {
              mKey: 'fns_requisites',
              isEditable: false,
              withEditing: false,
              fields: dialogFields,
            },
          });
        },
      },
      activeTab.value === 'fssp' && {
        key: 'add',
        icon: 'add',
        label: t('actions.add'),
        types: [ActionType.side],
        active: true,
        async handler() {
          const dialogFields: Array<ActiveFormField<any>> = [
            { key: 'serial_number', blockedBy: ['case_number'] },
            { key: 'case_number', blockedBy: ['serial_number'] },
            { key: 'court' },
          ].map((field) => ({
            ...field,
            field: field.key,
            options: {
              label: t(`add.${field.key}`),
              placeholder: t(`add.${field.key}`),
              modelModifiers: {
                'delay:0': true,
              },
            },
            type: ActiveFormFieldType.input,
            defaultValue: '',
          }));
          unsubCreateTypeDialog = await showDialog({
            title: 'Добавление ИП',
            component: IDialogComponent.model,
            addInRoute: false,
            ionDialogClassList: ['debtorAddExecutionModal'],
            payload: {
              mKey: 'add_enf_proc',
              isEditable: true,
              withEditing: true,
              initEditing: true,
              signal: signalId,
              fields: dialogFields,
            },
          });
        },
      },
      activeTab.value === 'accounts' && {
        icon: 'info',
        label: 'Добавить данные',
        key: 'bankRequisites',
        types: [ActionType.side],
        async handler() {
          const fields: Array<ActiveFormField<any>> = [
            { key: 'account_number' },
            { key: 'account_opening_date' },
            { key: 'account_type' },
            { key: 'credit_istitution_name' },
            { key: 'address' },
            { key: 'bic' },
            { key: 'inn' },
          ].map((field) => ({
            ...field,
            field: field.key,
            options: {
              label: t(`bankRequisites.${field.key}`),
              placeholder: t(`bankRequisites.${field.key}`),
            },
            type: field.key === 'account_opening_date' ? ActiveFormFieldType.date : ActiveFormFieldType.input,
            defaultValue: '',
          }));
          unsubCreateTypeDialog = await showDialog({
            title: 'Добавить данные банковского аккаунта должника',
            component: IDialogComponent.model,
            addInRoute: false,
            ionDialogClassList: ['debtorAddExecutionModal'],
            payload: {
              mKey: 'add_bank_account',
              isEditable: true,
              signal: SignalType.bankAccModelCreated,
              fields,
            },
          });
        },
      },
    ]).filter(Boolean) as ActiveTableAction<any, any>[]).map((action) => ({
      ...action,
      id: `modal_debtor_executions_tab_${activeTab.value}_tab_${action.key as string}_${action.types[0]}_action`,
    }) as ActiveTableAction<any, any>)),

    filters: computed(() => (([
      {
        key: '__tab',
        field: '__tab',
        type: ActiveFormFieldType.input,
        defaultValue: activeTab.value,
        isVisible: false,
      },
      {
        key: 'id',
        field: 'id',
        type: ActiveFormFieldType.input,
        defaultValue: debtor.value?.debtor.pk,
        isVisible: false,
      },
    ] as Array<ActiveFormField<any> | boolean>)
      .filter(Boolean) as Array<ActiveFormField<any>>)),

    columns: computed(() => ((
      (activeTab.value === 'fssp' ? fsspColumns : activeTab.value === 'fssp-requests' ? fsspRequestsColumns : [
        {
          key: 'index', width: '100px',
        },
        {
          key: 'files', width: '300px',
        },
        {
          key: 'ifns_number',
          width: '200px',
          getValue: () => debtor.value?.debtor_main_profile?.ifns?.name,
        },
        {
          key: 'serial_number',
          getValue: (r) => (r.serial_number ? `ВХ-${r.serial_number}` : null),
        },
        {
          key: 'request_id',
        },
        {
          key: 'request_date',
          format: ActiveTableColumnFormat.date,
        },
        {
          key: 'status_current',
        },
      ]) as Array<ActiveTableColumn<any> | boolean>)
      .filter(Boolean) as Array<ActiveTableColumn<any>>)
      .map((column) => ({
        ...column,
        field: column.field || column.key,
        label: column.label ?? t(`${activeTab.value === 'fssp-requests' ? 'fssp' : activeTab.value}.column.${String(column.key).split('.').pop()}`),
      }))),
  });

  /* const filtersModel = ref({
    debt_nature: null,
  }); */
  const filterConfig = computed(() => ({
    key: 'debt_nature',
    field: 'debt_nature',
    type: ActiveFormFieldType.select,
    options: {
      label: t('filters.debt_nature'),
      placeholder: t('filters.debt_nature'),
      options: [
        { value: 'communal' },
        { value: 'credit' },
        { value: 'tax' },
        { value: 'other' },
      ].map((item) => ({
        ...item,
        label: t(`debtNatures.${item.value}`),
      })),
    },
  }));

  const actionsMap = computed(() => ({
    leftActions: actions?.value.filter((f) => f.types.includes(ActionType.default)),
    rightActions: actions?.value.filter((f) => f.types.includes(ActionType.side)),
  }));

  onBeforeUnmount(subscribeToSignal(
    SignalType.model,
    async ({ id, model }:{ id: string; model: CreateWritoeModel }) => {
      if (id === signalId) {
        // Искать по номеру судебного решения по физиках можно только
        // с заполненной датой рождения
        if (model.case_number && !model.serial_number) {
          if (!isOrganization.value) {
            const ownerBirthDate = debtor.value?.debtor_tenant_profiles[0]?.birth_date;
            if (!ownerBirthDate) {
              showToast({
                label: t('errors.no_birth_date'),
                level: IToastLevel.danger,
                duration: 5000,
              });
              return;
            }
          }
        }
        clearErrors();

        const apiFields = {
          ...Object.entries(model).filter(([, value]) => !!value).reduce((acc, [key, value]) => ({
            ...acc,
            [key]: value,
          }), {}),
        };
        const apiModel = {
          ...apiFields,
          debtor_id: debtor.value!.debtor!.pk,
          company_id: companyId.value,
        } as CreateWritoeModel;

        const { status, response } = await createWritoe(apiModel);

        if (!status) {
          setErrors(
            Object.entries(response) as SourceErrors<keyof CreateWritoeModel>,
          );
        } else {
          await refetchFssp();
          unsubCreateTypeDialog?.();
          unsubCreateTypeDialog = null;
        }
      }
    },
  ));

  onBeforeUnmount(subscribeToSignal(
    SignalType.model,
    async ({ id, model }:{ id: string; model: DebtorBankAccount }) => {
      if (id === SignalType.bankAccModelCreated) {
        clearBankAccErrors();

        const { status, response } = await commonLegacyApiRequest({
          command: ApiCommand.createDebtorBankAccount,
          data: {
            ...model,
            account_opening_date: dateToApiDate(model.account_opening_date),
            use_for_print: false,
            debtor: debtor.value!.debtor!.pk,
          },
        });

        if (!status) {
          setBankAccErrors(
            Object.entries(response) as SourceErrors<keyof DebtorBankAccount>,
          );
        } else {
          await fetchBankAccounts();
          unsubCreateTypeDialog?.();
          unsubCreateTypeDialog = null;
        }
      }
    },
  ));

  const defaultWritoe = computed(() => (
    debtor.value?.writ_of_execution
  ));

  const defaultRecord = computed(() => (
    defaultWritoe.value
    && records.value.find((record) => (record.case_number === defaultWritoe.value?.case_number)
      || (record.production_number === defaultWritoe.value?.serial_number))?.id
  ));

  const onRowClick = async (row: any) => {
    await showDialog({
      component: IDialogComponent.fsspDetails,
      addInRoute: false,
      payload: {
        companyId: companyId.value,
        id: row.record!.id,
      },
    });
  };

  const {
    columns: bankAccountColumns,
    records: bankAccountRecords,
    actions: bankAccountActions,
    selected: bankAccountSelectedRecords,
    onSelectBankAccount,
    fetch: fetchBankAccounts,
  } = useBankAccounts(computed(() => debtor.value?.debtor.pk));

  return {
    t,
    activeTab,
    tabs,
    isLoading,

    records,
    columns,
    actions,
    limit,
    page,
    total,

    defaultRecord,
    defaultWritoe,
    filters,
    actionsMap,
    filtersModel,
    filterConfig,

    bankAccountColumns,
    bankAccountRecords,
    bankAccountActions,
    bankAccountSelectedRecords,
    onSelectBankAccount,
    isSelectable: false,
    openAuthProtectedFile,
    onRowClick,
  };
};
