import { Debtor } from '@/hooks/useDebtors';
import { SocketErrorMessage } from '@/utils/socket';
import { ProductionType } from '@/hooks/useConstructor';

export type SocketEventType = 'progress_event'|'model_event'

// export enum SocketEventType {
//   ProgressEvent = 'progress_event',
//   ModelEvent = 'model_event', // устаревший
// }

export type ProcessingSmsEvent = string;
export type MonitoringSmsEvent = string;
export type EventString = ProcessingSmsEvent|MonitoringSmsEvent;

export enum UploadEventStage {
  Parsing = 1,
  Syncing = 2,
  Caching = 3,
}

export enum UploadEventState {
  Parsing = 1,
  Success = 3,
  Error = 4,
}

export type TaskSubProgress = {
  progress: number;
  step: number;
  total: number;
  remaining_time: number;
}

export type TaskProgressData = {
  step: number;
  total: number;
}

export type TaskId = string;

export type SocketMessage = Record<string, any> & { data: { event: string } & Record<any, any> };

export type SocketMessageChartDataUpdated = {
  action: 'company_event';
  data: { type: 'company_event'; event: 'updated_tile_data_event'; tile_id: string};
  errors: string[];
}

export enum NotificationProgressStatusType {
  NEW = 'new',
  DELAYED = 'delayed',
  SEND = 'send',
  NOTSEND = 'notsend',
  DELIVERED = 'delivered',
  FAILED = 'failed',
  UNKNOWN = 'unknown',
  DENIED = 'denied',
  NOTFOUND = 'notfound'
}

export type ProcessingSmsEventSocketObj = {
  debtor_id: Debtor['pk'];
  phone_number: string;
  status_text: string;
  status: NotificationProgressStatusType;
}

export type DataFileEventSocketObj = ({
  status_text: string;
  status: number;
} | {
  name: string;
  url: string;
})

export const getSocketMessageTaskID = (message: SocketMessage): TaskId|null => {
  const matched = message.data.event.match(/\?id=(.+)/);
  if (!matched || !matched[1]) return null;
  return matched[1];
};

export type UpdatedActiveServiceCountSocketMessage = {
  action: 'company_event';
  data: {
    type: 'company_event';
    event: 'updated_active_service_count';
    id: number;
    service: number;
    count: number;
  };
}

export type ProcessingSmsSocketMessage = {
  action: 'progress_event';
  errors: string[];
  data: {
    debtor_total?: number;
    debtor_step?: number;
    model?: string; // deprecated
    description?: string;
    done?: boolean;
    event: string;
    encrypt_total?: number;
    encrypt_step?: number;
    obj: ProcessingSmsEventSocketObj & {
      state: number; status_text?: string; report?: string; archive?: string; is_success?: boolean; error_code: string;
    };
    progress: TaskProgressData;
    type: 'progress_event';
    error_message: any;
    stage: UploadEventStage;
    report_id?: number;
  };
}

export type DataFileSocketMessage = {
  action: 'progress_event'|'model_event';
  errors: string[];
  data: {
    debtor_total?: number;
    debtor_step?: number;
    model?: string; // deprecated
    description?: string;
    done?: boolean;
    event: string;
    encrypt_total?: number;
    encrypt_step?: number;
    // id документов для локального подписания
    waited_gds?: number[];
    obj: DataFileEventSocketObj & {
      id?: number;
      status?: number;
      status_name?: string;
      state: number;
      status_text?: string;
      report?: string;
      archive?: string;
      is_success?: boolean;
      error?: string;
      error_code?: string;
    } & {
      file_pdf?: string;
      file?: string;
      file_json?: string;
    };
    progress: TaskProgressData;
    type: 'progress_event';
    error: SocketErrorMessage;
    error_message: SocketErrorMessage;
    stage: UploadEventStage;
    report_id?: number;
    sub_progresses?: (TaskProgressData & { remaining_time: number })[];
  };
}

export type NotificationSocketMessage = {
  data: {
    type: 'company_event';
    event: 'send_notification';
    company_id: number;
    id: number;
    title: string;
    content: string;
    created_at: string;
  };
}

export type UpdatedCompanyBalanceSocketMessage = {
  errors: string[];
  data: {
    event: 'updated_balance';
    balance: string;
  };
}

export type UpdatedDebtorDataSocketMessage = {
  data: {
    'type':'company_event';
    'event':'updated_status';
    'debtor_id':number;
    'status':'ready_to_court';
    'substatus':'filling_in_court';
    'production_type': ProductionType;
  };
}

export type AisGorodCountSocketMessage = {
  action: 'company_event';
  errors: string[];
  data:{
    type: 'company_event';
    event: 'ais_gorod_requests';
    count: number;
  };
}

export type SocketMsg<T = Record<string, unknown>> = SocketMessage
  & (DataFileSocketMessage|ProcessingSmsSocketMessage|SocketMessageChartDataUpdated|AisGorodCountSocketMessage)

export const isProcessingEvent = (
  s: SocketMessage,
): s is ProcessingSmsSocketMessage => s.data.event
  .match(/^pretrial\/(voice|email|sms)\/\?id=/ig) !== null;

export const isDataFileEvent = (
  s: SocketMessage|SocketMessageChartDataUpdated,
): s is DataFileSocketMessage => s.data?.event?.startsWith('datafile/status/');

export const isMonitoringSmsEvent = (
  s: EventString,
): s is MonitoringSmsEvent => s === 'pretrial/sms/monitoring/';

export const isEventString = (v: any): v is EventString => {
  if (v) return true;
  return false;
};
