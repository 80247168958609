import { envIsMobile } from '@/utils/env';
import { isTablet } from '@/utils/device';

export const TooltipConfig = {
  trigger: 'axis',
  borderWidth: 0,
  backgroundColor: '#424857',
  textStyle: {
    color: 'white',
    fontSize: '12px',
    fontWeight: 400,
    width: '200px',
    display: 'block',
  },
  confine: true,
  // position: 'right',
  // position(point: any, params: any, dom: any, rect: any, size: any) {
  //   // eslint-disable-next-line prefer-rest-params
  //   // fixed at top
  //   return [point[0], '10%'];
  // },
  extraCssText: `
    border-radius: 8px;
    padding: 7px;
    display: block;
    max-width: 100%;
    overflow: hidden;
    white-space: break-spaces
    `,
};

const MobileTooltipConfig = {
  borderWidth: 0,
  backgroundColor: '#424857',
  textStyle: {
    color: 'white',
    fontSize: '12px',
    fontWeight: 400,
  },
  trigger: 'axis',
  axisPointer: {
    type: 'shadow',
    shadowStyle: {
      opacity: 0.2,
      color: '#000',
    },
  },
  appendToBody: true,
  className: 'chart-tooltip',
  triggerOn: 'click',
};

export const DefaultTooltipConfig = envIsMobile ? MobileTooltipConfig : TooltipConfig;

export const DefaultLegendConfig = {
  show: !!envIsMobile,
  selectedMode: false,
  orient: 'horizontal',
  left: 'center',
  align: 'left',
  // right: '100%',
  bottom: '0',
  icon: 'roundRect',
  lineStyle: {
    width: '100%',
  },
  // formatter: (name: string) => name.split(' ').join('\n'),
  // formatter: (name: string) => name.split(' ').map((word) => {
  //   if (word.length > 6) return `${word.substr(0, 6)}..`;
  //   return word;
  // }).join('\n'),
  // top: '80%',
  ...(
    envIsMobile
      ? isTablet
        ? {
          itemWidth: 14,
          itemHeight: 10,
          textStyle: {
            fontSize: 10,
            width: 300,
            overflow: 'break',
          },
        } : {
          itemWidth: 14,
          itemHeight: 10,
          textStyle: {
            width: envIsMobile ? window.screen.width - (30 + 30) : 300,
            overflow: 'break',
          },
        }
      : {}
  ),
};

type Color = string
type GradientColorStop = {
  offset: number;
  color: string;
}
type GradientConfig = {
  x: number;
  y: number;
  x2: number;
  y2: number;
  colorStops: GradientColorStop[];
}
export type ColorConfig = {
  color: Color;
  lineGradientConfig?: GradientConfig;
  gradientConfig?: GradientConfig;
}

export const CHART_COLOR_NAMES = {
  red: '#FF7D7D',
  orange: '#ffad3c',
  green: '#009688',
  green02: '#84d461',
  green03: '#5EF7CF',
  purple: '#495CFF',
  purple02: '#5C6AF5',
  purple03: '#6761D4',
  lightBlue3: '#389ee0',
  lightBlue2: '#CAE3F3',
  blue4: '#2248ff',
  blue3: '#5080CD',
  blue03: '#5eb3f7',
};

export type ChartColorNames = keyof (typeof CHART_COLOR_NAMES);

export const reversedChartColorNames = Object.fromEntries(
  Object.entries(CHART_COLOR_NAMES).map(
    ([name, hex]) => ([hex, name]),
  ),
) as Record<string, ChartColorNames>;

export const CHART_COLORS = [
  CHART_COLOR_NAMES.blue03,
  CHART_COLOR_NAMES.purple02,
  CHART_COLOR_NAMES.purple03,
  CHART_COLOR_NAMES.green03,
  CHART_COLOR_NAMES.green02,
  CHART_COLOR_NAMES.red,
  CHART_COLOR_NAMES.orange,
  CHART_COLOR_NAMES.blue3,
  CHART_COLOR_NAMES.blue4,
  CHART_COLOR_NAMES.lightBlue2,
  CHART_COLOR_NAMES.lightBlue3,
  CHART_COLOR_NAMES.green,
];
export const CHART_COLORS_1 = ['#5eb3f7', '#5C6AF5', '#e3e9f3'];
