import { computed, ComputedRef, Ref } from 'vue';
import { ActiveFormFieldType } from '@/hooks/useActiveForm';
import { mbToBites } from '@/hooks/useFileManager';
import { useModelDialog } from '@/hooks/useModelDialog';
import {
  DebtorPochtaTrack,
  CreateDebtorPochtaTrackModel,
  UpdateDebtorPochtaTrackModel, useDocumentsApi,
} from '@/hooks/useDocumentsApi';
import { ApiResponse, ApiResponseError, ApiResponseSuccess } from '@/service/api';
import { useLocalI18n } from '@/hooks/useLocalI18n';
import { ErrorsMap } from '@/hooks/useErrors';
import { ProductionType } from '@/hooks/useConstructor';
import { IToastLevel, useToast } from '@/hooks/useToast';

export const useDebtorPochtaModel = (debtorId: ComputedRef<number>, productionType: Ref<ProductionType>) => {
  const { t } = useLocalI18n('dialogs.debtorPochta');
  const { showToast } = useToast();
  const { fetchDebtorPochtaTrack, createDebtorPochtaTrack, updateDebtorPochtaTrack } = useDocumentsApi();
  const fields = computed(() => [
    {
      key: 'number',
      field: 'number',
      type: ActiveFormFieldType.input,
      options: {
        label: t('fields.number'),
        state: ['primary', 'short'],
        type: 'number',
      },
    },
    {
      key: 'weight',
      field: 'weight',
      type: ActiveFormFieldType.input,
      options: {
        label: t('fields.weight'),
        state: ['primary', 'short'],
        type: 'number',
      },
    },
    {
      key: 'file',
      field: 'file',
      type: ActiveFormFieldType.file,
      options: {
        label: t('fields.file'),
        state: ['primary', 'short'],
        maxSize: mbToBites(30),
      },
    },
    {
      key: 'file_f103',
      field: 'file_f103',
      type: ActiveFormFieldType.file,
      options: {
        label: t('fields.file_f103'),
        state: ['primary', 'short'],
        maxSize: mbToBites(30),
      },
    },
  ]);

  const modelDialogCreate = useModelDialog<
    CreateDebtorPochtaTrackModel,
    DebtorPochtaTrack
  >({
    key: 'debtor_pochta_add',
    title: t('title.create'),
    // @ts-ignore
    fields,
    onModelUpdate: async (payload) => {
      const { status, response } = await createOrEditDebtorPochtaTrack(payload);
      if (!status) {
        await showToast({
          level: IToastLevel.danger,
          label: t('toasts.error'),
        });
      } else {
        await showToast({
          level: IToastLevel.success,
          label: t('toasts.success.create'),
        });
      }
      return {
        status,
        response,
      };
    },
  });

  const modelDialogUpdate = useModelDialog<
    UpdateDebtorPochtaTrackModel,
    DebtorPochtaTrack,
    number
  >({
    key: 'debtor_pochta_edit',
    title: t('title.edit'),
    // @ts-ignore
    fields,
    getForm: async (id: DebtorPochtaTrack['id']) => fetchDebtorPochtaTrack(id),
    onModelUpdate: async (payload: UpdateDebtorPochtaTrackModel, id: UpdateDebtorPochtaTrackModel['id']) => {
      const { status, response } = await createOrEditDebtorPochtaTrack(payload, id);
      if (!status) {
        await showToast({
          level: IToastLevel.danger,
          label: t('toasts.error'),
        });
      } else {
        await showToast({
          level: IToastLevel.success,
          label: t('toasts.success.edit'),
        });
      }
      return {
        status,
        response,
      };
    },
  });

  function createOrEditDebtorPochtaTrack(payload: CreateDebtorPochtaTrackModel | UpdateDebtorPochtaTrackModel, id?: UpdateDebtorPochtaTrackModel['id']) {

    const { errorsMap, hasErrors } = Object.entries(payload).reduce(
      (acc, [key, value]) => {
        if (['file'].includes(key)) {
          if (!value) {
            acc.errorsMap[key] = ['Поле обязательно'];
            acc.hasErrors = true;
          }
        }
        return acc;
      }, {
        errorsMap: {},
        hasErrors: false,
      } as { errorsMap: ErrorsMap<any>; hasErrors: boolean},
    );

    if (hasErrors) {
      return {
        status: false,
        response: errorsMap,
      } as ApiResponseError;
    }

    type Keys = keyof typeof payload;

    // @ts-ignore
    const prepPayload: UpdateDebtorPochtaTrackModel = Object.fromEntries(
      (Object.keys(payload) as Keys[]).filter(
        (k) => (payload[k] !== null && ['file', 'file_f103', 'attachment'].includes(k) ? typeof payload[k] !== 'string' : true),
      ).map((k) => ([
        k, payload[k],
      ])),
    );

    if (id) {
      return updateDebtorPochtaTrack(prepPayload as UpdateDebtorPochtaTrackModel) as Promise<ApiResponse<DebtorPochtaTrack>>;
    }

    return createDebtorPochtaTrack({
      ...payload,
      debtor: debtorId.value,
      production_type: productionType.value,
    } as CreateDebtorPochtaTrackModel) as Promise<ApiResponse<DebtorPochtaTrack>>;
  }

  return {
    openCreateDialog: modelDialogCreate.showDialog,
    openUpdateDialog: modelDialogUpdate.showDialog,
  };
};
