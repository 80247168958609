import {
  computed, h, ref, Ref, watchEffect,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { ProductionType, ReportSaveType, useConstructor } from '@/hooks/useConstructor';
import { Court } from '@/hooks/useCourts';
import { Company, useCompanies } from '@/hooks/useCompanies';
import { ApiCommand, ListingRequestSource, ListingResponse } from '@/store/modules/api';
import {
  Bailiff, Debtor, DebtorMainProfile, DebtorStatus, DebtorSubstatus,
} from '@/hooks/useDebtors';
import { ActionType, ActiveTableAction } from '@/components/activeTable/useActiveTable';
import { useLocalI18n } from '@/hooks/useLocalI18n';
import { useDefaultCompany } from '@/hooks/useDefaultCompany';
import { IDialogComponent, useDialog } from '@/hooks/useDialog';
import { IBtnState } from '@/components/btn/useBtn';
import { IToastLevel, useToast } from '@/hooks/useToast';
import { useSocket } from '@/hooks/useSocket';
import { SignalType, useSignal } from '@/hooks/useSignal';
import { MoveDebtorsModel, MoveDebtorsResult } from '@/types/debtor/move-debtors';
import { useProtectedDefaultCompany } from '@/hooks/useProtectedDefaultCompany';
import { getMoveDebtorsResultToastProps } from '@/utils/move-debtor';
import { DataFileFilters } from '@/types/datafile';
import { useDebtorCompositeUpdate } from '@/hooks/useDebtorCompositeUpdate';
import { useProtectedInject } from '@/hooks/useProtectedInject';
import { IsIonicApp } from '@/symbols';
import { ProgressTask } from '@/store/modules/tasksProgress';
import { FinanceServiceHandle } from '@/hooks/useFinance';
import { commonLegacyApiRequest } from '@core/service/commonService';
import { useFetchDebtorsForAction } from '@/pages/debtors/_module/useFetchDebtorsForAction';
import { copyDebtorsToClipboard } from '@/pages/debtors/_module/useDebtorsQueryUtils';
import { useSelectRecognitionDocumentType } from '@/pages/debtors/_module/useSelectRecognitionDocumentType';
import { useTaskProgress } from '@/hooks/useTaskProgress';

import { ArchiveAction, useRemoveDebtorsHandler } from '@/pages/debtors/_module/tableActions/removeDebtors';
import { useNotificationsDialog } from '@/pages/debtors/_module/tableActions/useNotificationsDialog';
import { SettingEnabledKey, useSettingEnabled } from '@/hooks/ais/useSettingEnabled';
import { useAisGorodUpdate } from '@/pages/debtors/_module/tableActions/aisGorodUpdate/useAisGorodUpdate';
import { useBorUpdate } from '@/pages/debtors/_module/tableActions/borUpdate/useBorUpdate';
import { useMegapolisUpdate } from '@/pages/debtors/_module/tableActions/megapolisUpdate/useMegapolisUpdate';
import { usePikUpdate } from '@/pages/debtors/_module/tableActions/pikUpdate/usePikUpdate';
import { useAsuUpdate } from '@/pages/debtors/_module/tableActions/asuUpdate/useAsuUpdate';
import SelectInput from '@/components/selectInput/SelectInput.vue';

export type SmsStatus = string;
export type VoiceStatus = string;

export enum CompanyModuleType {
  Communal = 1,
  Vehicles = 2,
  Service = 3,
  Banking = 4,
}

export type DebtorQuery = {
  pk: string;
  full_name: string;
  production_type: ProductionType;
  production_type_not: ProductionType;
  address: string;
  personal_account: string;
  debtor_ids: Array<DebtorMainProfile['id']>;
  has_writ_of_execution: boolean;
  has_sms: boolean;
  sms_status: SmsStatus;
  sms_status_not: SmsStatus;
  has_voice: boolean;
  voice_status: VoiceStatus;
  voice_status_not: VoiceStatus;
  status_name: DebtorStatus['status'];
  status_name_not: DebtorStatus['status'];
  substatus_name: DebtorSubstatus['substatus'];
  substatus_name_not: DebtorSubstatus['substatus'];
  phone_numbers: string[];
  debtor_debt_min: number;
  debtor_debt_max: number;
  penalty_min: number;
  accrual_min: number;
  paid_up_min: number;
  regional_court: string;
  regional_court_id: Court['id'];
  magistrate_court: string;
  magistrate_court_id: Court['id'];
  bailiff_id: Bailiff['pk'];
  bailiffs: string;
  serial_number: string;
  case_number: string;
  writs_null: boolean;
  debtor_fees: number;
  company_id: Company['id'];
  juicial_status: DebtorStatus['status'];
  fee_status: string;
  o: string;
  attached_users: string;
}

export type FetchDebtorsModel = ListingRequestSource<DebtorQuery>;
export type FetchDebtorsResponse = ListingResponse<Debtor>;

export type FetchDebtorModel = {
  id: Debtor['pk'];
  companyId: Company['id'];
  module: CompanyModuleType;
  productionType: ProductionType;
  signal?: any;
};
export type FetchDebtorResponse = Debtor;

export const useDebtorsActions = (
  module: Ref<ProductionType>,
) => {
  const { t } = useLocalI18n('debtors');
  const { t: rootT } = useI18n();
  const isIonicApp = useProtectedInject(IsIonicApp);
  const fetchDebtorsForAction = useFetchDebtorsForAction();
  const { permissionsMap, employeeIsManager } = useDefaultCompany();
  const { companyModuleType, companyId } = useProtectedDefaultCompany();
  const { fetchCompany } = useCompanies();
  const { getRemoveHandler } = useRemoveDebtorsHandler();
  const { showDialog, confirmDialog } = useDialog();
  const { dispatchSignal } = useSignal();
  const { startTaskProgress } = useTaskProgress();
  const { awaitSignalResponse } = useSignal();
  const { showToast } = useToast();
  const { render, renderDebtorsData, renderDebtorsDataPostal } = useConstructor();
  const { showNotificationDialog } = useNotificationsDialog();
  const { subscribe } = useSocket();
  const store = useStore();

  const hasWritePermission = computed(
    () => !!permissionsMap.value[module.value],
  );

  const selectRecognitionDocumentType = useSelectRecognitionDocumentType(computed(() => ([
    {
      label: 'Платежное поручение',
      value: 'pp',
      id: 'modal_recognition_item_pp',
    },
    {
      label: 'Документы жилищного учета',
      value: 'vdk',
      id: 'modal_recognition_item_vdk',
    },
  ])));

  const getActionHintAndActiveStatus = (
    handleKeys: FinanceServiceHandle[],
    translationKey: string,
  ) => ({
    hint: t(`action.${translationKey}`),
  });

  const showNonSelectedItemsDialog = async (title: string, key: string) => {
    const { closeDialog } = await confirmDialog({
      key,
      title,
      cancelLabel: rootT('other.ok'),
      withConfirmation: false,
    });
    closeDialog();
  };

  const isSettingsDialogVisible = ref(false);
  const setFeeSaveType = ref<string>(ReportSaveType.excel);

  const updateDebtorsData = useDebtorCompositeUpdate(() => {
    return dispatchSignal(SignalType.debtorsUpdated);
  });
  const updateAisGorodData = useAisGorodUpdate(() => {
    return dispatchSignal(SignalType.debtorsUpdated);
  });
  const updateBorData = useBorUpdate(() => {
    return dispatchSignal(SignalType.debtorsUpdated);
  });
  const updateBorDataFullPeriod = useBorUpdate(() => {
    return dispatchSignal(SignalType.debtorsUpdated);
  }, true);
  const updateMegapolisData = useMegapolisUpdate(() => {
    return dispatchSignal(SignalType.debtorsUpdated);
  });
  const updatePikData = usePikUpdate(() => {
    return dispatchSignal(SignalType.debtorsUpdated);
  });
  const updateAsuData = useAsuUpdate(() => {
    return dispatchSignal(SignalType.debtorsUpdated);
  });

  const aisGorodEnabled = useSettingEnabled(companyId, SettingEnabledKey.aisGorod);
  const borEnabled = useSettingEnabled(companyId, SettingEnabledKey.bor);
  const megapolisEnabled = useSettingEnabled(companyId, SettingEnabledKey.megapolis);
  const pikEnabled = useSettingEnabled(companyId, SettingEnabledKey.pik);
  const asuEnabled = useSettingEnabled(companyId, SettingEnabledKey.asu);

  const isNotExecutive = computed(() => (
    [ProductionType.pretrial, ProductionType.judicial].includes(module.value)
  ));

  const actions = computed<Array<ActiveTableAction<Debtor, 'pk'>>>(() => (
    (
      [
        {
          key: 'copy',
          hint: t('action.copy'),
          types: [ActionType.context],
          icon: 'printer',
          group: 1,
          async handler({
            selectedItems, allSelected, records, total,
          }, record) {
            const copyToClipboard = (debtors: Debtor[]) => copyDebtorsToClipboard(
              debtors as unknown as Debtor[], (d) => d.debtor.personal_account,
            );
            if (allSelected) {
              const result = await fetchDebtorsForAction(
                total as number,
                'Копирование ЛС',
              );
              console.log('fetchDebtorsForAction', result);
              if (result.status) {
                await showDialog({
                  component: IDialogComponent.confirmCopyClipboard,
                  payload: {
                    onConfirm: () => {
                      copyToClipboard(result.response);
                      showToast({
                        level: IToastLevel.success,
                        label: 'Копирование ЛС',
                        message: 'Данные скопированы в буфер обмена',
                      });
                    },
                  },
                  addInRoute: false,
                });
              }
            } else {
              const recordsFinal = selectedItems.length ? records : [record];
              copyToClipboard(recordsFinal as unknown as Debtor[]);
              await showToast({
                level: IToastLevel.success,
                label: 'Копирование ЛС',
                message: 'Данные скопированы в буфер обмена',
              });
            }
          },
        },
        {
          key: 'copyAddress',
          hint: t('action.copyAddress'),
          types: [ActionType.context],
          icon: 'printer',
          group: 1,
          async handler({
            selectedItems, allSelected, records, total,
          }, record) {
            const copyToClipboard = (debtors: Debtor[]) => copyDebtorsToClipboard(
              debtors as unknown as Debtor[], (d) => (d.debtor_main_profile?.organization
                ? d.debtor_main_profile?.organization?.legal_address
                : d.debtor_main_profile?.address),
            );
            if (allSelected) {
              const result = await fetchDebtorsForAction(
                total as number,
                'Копирование Адресов',
              );
              if (result.status) {
                await showDialog({
                  component: IDialogComponent.confirmCopyClipboard,
                  payload: {
                    onConfirm: () => {
                      copyToClipboard(result.response);
                      showToast({
                        level: IToastLevel.success,
                        label: 'Копирование Адресов',
                        message: 'Данные скопированы в буфер обмена',
                      });
                    },
                  },
                  addInRoute: false,
                });
              }
            } else {
              const recordsFinal = selectedItems.length ? records : [record];
              copyToClipboard(recordsFinal as unknown as Debtor[]);
              await showToast({
                level: IToastLevel.success,
                label: 'Копирование Адресов',
                message: 'Данные скопированы в буфер обмена',
              });
            }
          },
        },
        {
          key: 'copyName',
          hint: t('action.copyName'),
          types: [ActionType.context],
          icon: 'printer',
          group: 1,
          async handler({
            selectedItems, allSelected, records, total,
          }, record) {
            const copyToClipboard = (debtors: Debtor[]) => copyDebtorsToClipboard(
              debtors as unknown as Debtor[], (d) => (!d.debtor_main_profile?.organization
                ? d.debtor_main_profile?.full_name
                : d.debtor_main_profile?.organization?.name_short ?? d.debtor_main_profile?.full_name),
            );
            if (allSelected) {
              const result = await fetchDebtorsForAction(
                total as number,
                'Копирование Имен',
              );
              if (result.status) {
                await showDialog({
                  component: IDialogComponent.confirmCopyClipboard,
                  payload: {
                    onConfirm: () => {
                      copyToClipboard(result.response);
                      showToast({
                        level: IToastLevel.success,
                        label: 'Копирование Имен',
                        message: 'Данные скопированы в буфер обмена',
                      });
                    },
                  },
                  addInRoute: false,
                });
              }
            } else {
              const recordsFinal = selectedItems.length ? records : [record];
              copyToClipboard(recordsFinal as unknown as Debtor[]);
              await showToast({
                level: IToastLevel.success,
                label: 'Копирование Имен',
                message: 'Данные скопированы в буфер обмена',
              });
            }
          },
        },
        {
          key: 'print',
          hint: t('action.print'),
          types: [ActionType.default, ActionType.context],
          icon: 'printer',
          group: 1,
          async handler({ selectedItems, allSelected }, record) {
            if (!allSelected && !selectedItems.length && !record) {
              await showNonSelectedItemsDialog(rootT('print.notSelected'), 'print');
              return;
            }
            await showDialog({
              component: IDialogComponent.print,
              addInRoute: false,
              payload: {
                productionType: module.value,
                debtorIds: selectedItems?.length ? selectedItems.map(Number) : (allSelected ? [] : [(record as Debtor).debtor.pk!]),
              },
            });
          },
        },
        module.value === ProductionType.pretrial && {
          key: 'lock',
          hint: t('action.lock'),
          types: [ActionType.default, ActionType.context],
          icon: 'lock',
          group: 1,
          async handler({ selectedItems, allSelected, records }) {
            if (!allSelected && !selectedItems.length) {
              await showNonSelectedItemsDialog(rootT('debtors.notSelected'), 'debtors');
              return;
            }
            const {
              result,
              closeDialog,
            } = await confirmDialog({
              title: rootT('lock.dialog.title'),
              cancelMixin: {
                isVisible: false,
              },
            });
            closeDialog();
            if (!result) {
              return;
            }
            const closeToast = await showToast({
              label: 'Выполнение операции..',
              level: IToastLevel.info,
              duration: null,
            });
            await Promise.all(
              records.map(
                (debtor) => (!debtor.debtor.notification_lock ? commonLegacyApiRequest({
                  command: ApiCommand.lockDebtorNotifications,
                  data: { debtor: debtor.debtor.pk },
                }) : commonLegacyApiRequest({
                  command: ApiCommand.unlockDebtorNotifications,
                  params: { id: debtor.debtor.notification_lock },
                })),
              ),
            );
            await closeToast();
            await dispatchSignal(SignalType.debtorsUpdated);
          },
        },
        module.value === ProductionType.judicial && {
          key: 'setOfCharges',
          hint: t('action.setOfCharges'),
          types: [ActionType.default],
          icon: 'bill',
          group: 1,
          async handler({ selectedItems }) {
            // if (!allSelected && !selectedItems.length) {
            //   await showNonSelectedItemsDialog(rootT('setOfCharges.notSelected'));
            //   return;
            // }
            const {
              result,
              closeDialog,
            } = await confirmDialog({
              title: rootT('setOfCharges.title'),
              cancelMixin: {
                isVisible: false,
              },
              confirmMixin: {
                label: rootT('setOfCharges.submit'),
                icon: 'eye',
                state: [IBtnState.tertiary, IBtnState.vertical],
              },
            });
            closeDialog();

            if (!result) {
              return;
            }

            await showToast({
              message: 'setOfCharges.toast.message',
              level: IToastLevel.info,
            });

            const localFilters = await awaitSignalResponse<Record<any, any>
              >(
                SignalType.getDebtorFilters,
                SignalType.debtorFilters,
              );

            const { status, response } = await render({
              company_id: companyId.value!,
              production_type: module.value,
              template_type_id: 3,
              filters: {
                ...(selectedItems.length
                  ? { debtor_ids: selectedItems.map(Number) }
                  : { ...(localFilters || {}) }
                ),
              },
            });

            if (status) {
              const id = response.uuid;

              await store.dispatch('tasksProgress/startTask', {
                task: {
                  companyId: companyId.value,
                  uuid: id,
                  id,
                  key: id,
                  progressbars: [],
                  action: 'startExportTask',
                  label: 'startExportTask',
                },
              } as { task: ProgressTask });
              await dispatchSignal(SignalType.debtorsUpdated);
            } else {
              await showToast({
                message: 'setOfCharges.toast.failure',
                level: IToastLevel.danger,
              });
            }
          },
        },
        module.value === ProductionType.judicial && {
          key: 'postal',
          hint: t('action.postal'),
          types: [ActionType.default],
          icon: 'postal',
          group: 1,
          async handler({ selectedItems, allSelected }) {
            if (!allSelected && !selectedItems.length) {
              await showNonSelectedItemsDialog(rootT('postal.notSelected'), 'postal');
              return;
            }

            const {
              result,
              closeDialog,
            } = await confirmDialog({
              key: 'postal',
              title: rootT('postal.dialog.title'),
              cancelMixin: {
                isVisible: false,
              },
              confirmMixin: {
                label: rootT('postal.submit'),
                icon: 'eye',
                state: [IBtnState.tertiary, IBtnState.vertical],
              },
            });
            closeDialog();

            if (!result) {
              return;
            }

            const companyResponse = await fetchCompany(companyId.value);

            if (!companyResponse.status) {
              await showToast({
                label: 'pureLabel',
                params: {
                  label: 'Ошибка',
                },
                level: IToastLevel.danger,
                duration: 500,
              });
              return;
            }

            await showToast({
              message: 'postal.toast.message',
              level: IToastLevel.info,
            });
            const localFilters = await awaitSignalResponse<Record<any, any>
            >(
              SignalType.getDebtorFilters,
              SignalType.debtorFilters,
            );

            const { status, response } = await renderDebtorsDataPostal({
              company_id: companyId.value!,
              filters: {
                ...(selectedItems.length
                  ? { debtor_ids: selectedItems.map(Number) }
                  : { ...(localFilters || {}) }
                ),
                company_id: companyId.value!,
                module: companyModuleType.value,
              },
              // @ts-ignore
              penalty_key_rate_mode: companyResponse.response.penalty_key_rate_mode,
              services: companyResponse.response.services?.map(({ name }) => name),
              moratorium_enabled: true,
            });

            if (status) {
              const id = response.uuid;

              await store.dispatch('tasksProgress/startTask', {
                task: {
                  companyId: companyId.value,
                  uuid: id,
                  id,
                  key: id,
                  progressbars: [],
                  action: 'startExportTask',
                  label: 'startExportTask',
                },
              } as { task: ProgressTask });
              await dispatchSignal(SignalType.debtorsUpdated);
            } else {
              await showToast({
                message: 'postal.toast.failure',
                level: IToastLevel.danger,
              });
            }
          },
        },
        module.value === ProductionType.judicial && {
          key: 'fee',
          hint: t('action.fee'),
          types: [ActionType.default],
          icon: 'clipboard',
          group: 1,
          async handler({ selectedItems }) {
            // if (!allSelected && !selectedItems.length) {
            //   await showNonSelectedItemsDialog(rootT('dutyForm.notSelected'));
            //   return;
            // }

            const {
              result,
              closeDialog,
            } = await confirmDialog({
              key: 'fee',
              title: rootT('dutyForm.title'),
              cancelMixin: {
                isVisible: false,
              },
              confirmMixin: {
                label: rootT('dutyForm.submit'),
                icon: 'eye',
                state: [IBtnState.tertiary, IBtnState.vertical],
              },
              extraContent: computed(() => h('div', {
                style: 'width: 100%',
              }, [
                // @ts-ignore
                h(SelectInput, {
                  id: 'modal_fee_select_format',
                  state: ['primary', 'horizontal'],
                  options: [
                    {
                      label: 'xlsx',
                      value: ReportSaveType.excel,
                    },
                    {
                      label: ReportSaveType.pp3,
                      value: ReportSaveType.pp3,
                    },
                  ],
                  modelValue: setFeeSaveType.value,
                  'onUpdate:modelValue': (v: string) => {
                    setFeeSaveType.value = v;
                  },
                }),
              ])),
            });
            closeDialog();

            if (!result) {
              return;
            }

            const companyResponse = await fetchCompany(companyId.value);

            if (!companyResponse.status) {
              await showToast({
                label: 'pureLabel',
                params: {
                  label: 'Ошибка',
                },
                level: IToastLevel.danger,
                duration: 500,
              });
              return;
            }

            await showToast({
              message: 'dutyForm.toast.message',
              level: IToastLevel.info,
            });
            const localFilters = await awaitSignalResponse<Record<any, any>
              >(
                SignalType.getDebtorFilters,
                SignalType.debtorFilters,
              );

            const { status, response } = await renderDebtorsData({
              company_id: companyId.value!,
              filters: {
                ...(selectedItems.length
                  ? { debtor_ids: selectedItems.map(Number) }
                  : { ...(localFilters || {}) }
                ),
                company_id: companyId.value!,
                module: companyModuleType.value,
              },
              // @ts-ignore
              penalty_key_rate_mode: companyResponse.response.penalty_key_rate_mode,
              services: companyResponse.response.services?.map(({ name }) => name),
              moratorium_enabled: true,
              save_type: setFeeSaveType.value,
            });

            if (status) {
              const id = response.uuid;

              await store.dispatch('tasksProgress/startTask', {
                task: {
                  companyId: companyId.value,
                  uuid: id,
                  id,
                  key: id,
                  progressbars: [],
                  action: 'startExportTask',
                  label: 'startExportTask',
                },
              } as { task: ProgressTask });
              await dispatchSignal(SignalType.debtorsUpdated);
            } else {
              await showToast({
                message: 'dutyForm.toast.failure',
                level: IToastLevel.danger,
              });
            }
          },
        },
        module.value === ProductionType.judicial
          && companyModuleType.value !== CompanyModuleType.Vehicles
          && {
            key: 'egrn',
            hint: t('action.egrn'),
            types: [ActionType.default],
            icon: 'egrn',
            group: 1,
            async handler({ selectedItems, allSelected }) {
              if (!allSelected && !selectedItems.length) {
                await showNonSelectedItemsDialog(rootT('egrn.notSelected'), 'egrn');
                return;
              }
              await showDialog({
                component: IDialogComponent.egrn,
                addInRoute: false,
                payload: {
                  productionType: module.value,
                  debtorIds: selectedItems.map(Number),
                },
              });
            },
          },
        // module.value === ProductionType.pretrial && {
        //   key: 'claim',
        //   hint: t('action.claim'),
        //   types: [ActionType.default],
        //   icon: 'file-check',
        //   group: 1,
        //   async handler(payload) {
        //     await showNotificationDialog('claim', payload);
        //   },
        // },
        module.value === ProductionType.pretrial && {
          key: 'sms',
          id: '',
          hint: t('action.sms'),
          types: [ActionType.default],
          icon: 'sms',
          group: 1,
          async handler(payload) {
            await showNotificationDialog('sms', payload);
          },
        },
        module.value === ProductionType.pretrial && {
          key: 'voice',
          hint: t('action.voice'),
          types: [ActionType.default],
          icon: 'microphone',
          group: 1,
          async handler(payload) {
            await showNotificationDialog('voice', payload);
          },
        },
        module.value === ProductionType.pretrial && {
          key: 'email',
          hint: t('action.email'),
          types: [ActionType.default],
          icon: 'envelope',
          group: 1,
          async handler(payload) {
            await showNotificationDialog('email', payload);
          },
        },
        {
          key: 'update', // enrichment update
          types: [ActionType.default],
          icon: 'update',
          group: 1,
          async handler({ selectedItems, allSelected }) {
            if (!allSelected && !selectedItems.length) {
              await showNonSelectedItemsDialog(rootT('update.notSelected'), 'update');
              return;
            }
            await updateDebtorsData(
              module.value,
              selectedItems.map(Number),
            );
          },
          ...getActionHintAndActiveStatus(['CREATE_DOC', 'CREATE_ISK', 'CREATE_DOC_EDS'], 'update'),
        },
        {
          key: 'recognition',
          types: [ActionType.default],
          hint: t('action.recognition'),
          icon: 'documents',
          group: 1,
          async handler({ selectedItems, allSelected }) {
            if (!allSelected && !selectedItems.length) {
              await showNonSelectedItemsDialog(rootT('recognition.notSelected'), 'recognition');
              return;
            }

            const localFilters = await awaitSignalResponse<Record<any, any>
              >(
                SignalType.getDebtorFilters,
                SignalType.debtorFilters,
              );

            const filters = {
              company_id: localFilters.company_id,
              production_type: localFilters.production_type,
              module: companyModuleType.value,
              ...(selectedItems.length ? { debtor_ids: [...selectedItems] } : { ...localFilters }),
            } as DataFileFilters;
            const document_type = await selectRecognitionDocumentType();
            if (!document_type) {
              return;
            }
            const result = await commonLegacyApiRequest<{ uuid: string }>({
              command: ApiCommand.debtorStatusDocumentRecognition,
              params: {
                company_id: companyId.value,
              },
              data: {
                document_type,
                filters,
              },
            });
            if (!result.status) {
              await showToast({
                label: 'Ошибка формирования запроса на распознавание документов',
                level: IToastLevel.danger,
              });
              return;
            }
            const uuid = result.response.uuid;
            await startTaskProgress({
              key: uuid as string,
              id: uuid,
              uuid: uuid as string,
              action: 'startImportTask',
              label: 'Документы',
              companyId: companyId.value,
              progressbars: [
                {
                  key: 'uploading',
                  label: 'Подготовка',
                  current: 1,
                  max: 1,
                  message: '',
                },
                {
                  key: 'parsing',
                  label: 'exchange.import.form.toast.submit.progresses.parsing',
                  current: 0,
                  max: 0,
                  message: '',
                  showMessageInsteadOfLabel: true,
                },
              ],
            });
            await subscribe({
              condition: (payload) => {
                console.log('socket condition', payload);
                return payload.data.event.includes(result.response.uuid);
              },
              handler: (payload) => {
                console.log('socket handler', payload);
              },
            });
          },
        },
        aisGorodEnabled.value && isNotExecutive.value && {
          key: 'aisGorodUpdate',
          types: [ActionType.default],
          icon: 'ais-gorod-update',
          group: 1,
          hint: t('action.aisGorodUpdate'),
          async handler({ selectedItems, allSelected }) {
            if (!allSelected && !selectedItems.length) {
              await showNonSelectedItemsDialog(t('notSelected'), 'ais_gorod_upd');
              return;
            }
            await updateAisGorodData(
              module.value,
              selectedItems.map(Number),
            );
          },
        },
        borEnabled.value && isNotExecutive.value && {
          key: 'borUpdate',
          types: [ActionType.default],
          icon: 'ais-gorod-update',
          group: 1,
          hint: t('action.borUpdate'),
          async handler({ selectedItems, allSelected }) {
            if (!allSelected && !selectedItems.length) {
              await showNonSelectedItemsDialog(t('notSelected'), 'bor_update');
              return;
            }
            await updateBorData(
              module.value,
              selectedItems.map(Number),
            );
          },
        },
        borEnabled.value && isNotExecutive.value && {
          key: 'borUpdateFull',
          types: [ActionType.default],
          icon: 'ais-gorod-update',
          group: 1,
          hint: t('action.borUpdateFull'),
          async handler({ selectedItems, allSelected }) {
            if (!allSelected && !selectedItems.length) {
              await showNonSelectedItemsDialog(t('notSelected'), 'bor_update_full');
              return;
            }
            await updateBorDataFullPeriod(
              module.value,
              selectedItems.map(Number),
            );
          },
        },
        megapolisEnabled.value && isNotExecutive.value && {
          key: 'megapolisUpdate',
          types: [ActionType.default],
          icon: 'ais-gorod-update',
          group: 1,
          hint: t('action.megapolisUpdate'),
          async handler({ selectedItems, allSelected }) {
            if (!allSelected && !selectedItems.length) {
              await showNonSelectedItemsDialog(t('notSelected'), 'megapolis_update');
              return;
            }
            await updateMegapolisData(
              module.value,
              selectedItems.map(Number),
            );
          },
        },
        pikEnabled.value && isNotExecutive.value && {
          key: 'pikUpdate',
          types: [ActionType.default],
          icon: 'ais-gorod-update',
          group: 1,
          hint: t('action.pikUpdate'),
          async handler({ selectedItems, allSelected }) {
            if (!allSelected && !selectedItems.length) {
              await showNonSelectedItemsDialog(t('notSelected'), 'sql_update');
              return;
            }
            await updatePikData(
              module.value,
              selectedItems.map(Number),
            );
          },
        },
        asuEnabled.value && isNotExecutive.value && {
          key: 'asuUpdate',
          types: [ActionType.default],
          icon: 'ais-gorod-update',
          group: 1,
          hint: t('action.asuUpdate'),
          async handler({ selectedItems, allSelected }) {
            if (!allSelected && !selectedItems.length) {
              await showNonSelectedItemsDialog(t('notSelected'), 'asu_update');
              return;
            }
            await updateAsuData(
              module.value,
              selectedItems.map(Number),
            );
          },
        },
        {
          key: 'attachEmployee',
          hint: t('action.attachEmployee'),
          types: [ActionType.default],
          icon: 'attach-employee',
          group: 1,
          async handler({ selectedItems }) {
            if (selectedItems.length === 0) {
              await showNonSelectedItemsDialog(rootT('attachEmployee.notSelected'), 'attachEmployee');
              return;
            }

            await showDialog({
              component: IDialogComponent.attachEmployeeToDebtor,
              addInRoute: false,
              payload: {
                selected: selectedItems,
              },
            });
          },
        },
        employeeIsManager.value && {
          key: ArchiveAction,
          hint: rootT(`${ArchiveAction}.title`),
          types: [ActionType.default],
          icon: 'archive',
          group: 1.5,
          handler: getRemoveHandler(ArchiveAction),
        },
        !isIonicApp && (module.value !== ProductionType.executive) && hasWritePermission.value && {
          key: 'settings',
          hint: t('action.settings'),
          types: [ActionType.default],
          icon: 'gears',
          active: isSettingsDialogVisible.value,
          group: 2,
          async handler() {
            if (isIonicApp) {
              await showDialog({
                component: IDialogComponent.automatizing,
                addInRoute: false,
                payload: {
                  productionType: module.value,
                },
              });
            } else {
              isSettingsDialogVisible.value = !isSettingsDialogVisible.value;
            }
          },
        },
        (module.value !== ProductionType.executive) && {
          key: `change_status_${module.value}`,
          hint: t(`action.change_status_${module.value}`),
          types: [ActionType.default],
          icon: module.value === ProductionType.pretrial ? 'to-judicial' : 'to-executive',
          group: 1.5,
          async handler({ selectedItems, allSelected }) {
            if (!allSelected && !selectedItems.length) {
              await showNonSelectedItemsDialog(rootT('debtorStatus.notSelected'), `change_status_${module.value}`);
              return;
            }
            const to = module.value === ProductionType.pretrial
              ? ProductionType.judicial
              : ProductionType.executive;
            const title = rootT(`moveDebtorsDialog.${module.value}.title`);
            const message = rootT(`moveDebtorsDialog.${module.value}.message`);

            const { result, closeDialog } = await confirmDialog({
              key: `change_status_${module.value}`,
              title,
              message,
              confirmLabel: rootT('moveDebtorsDialog.confirmLabel'),
            });

            if (!result) {
              closeDialog();
              return;
            }

            const closeInfoToast: () => void = await showToast({
              label: rootT('moveDebtorsDialog.toast.label'),
              message: rootT('moveDebtorsDialog.toast.message'),
              duration: null,
              level: IToastLevel.info,
            });
            const filters = {
              company_id: companyId.value,
              production_type: module.value,
              module: companyModuleType.value,
            } as MoveDebtorsModel['filters'];
            if (!allSelected) {
              filters.debtor_ids = [...selectedItems] as unknown as Debtor['pk'][];
            }

            const moveResult = (await store.dispatch('debtors/moveDebtors', {
              company_id: companyId.value,
              to,
              filters,
            } as MoveDebtorsModel)) as MoveDebtorsResult;
            closeInfoToast();
            closeDialog();

            await dispatchSignal(SignalType.debtorsUpdated);
            await showToast({
              label: 'moveDebtorsDialog.toast.label',
              duration: 5000,
              ...getMoveDebtorsResultToastProps(moveResult, rootT),
            });
          },
        },
        module.value !== ProductionType.executive && {
          key: 'status',
          label: t('action.status'),
          types: [ActionType.line],
          async handler({ selectedItems, allSelected }) {
            if (!allSelected && !selectedItems.length) {
              await showNonSelectedItemsDialog(rootT('debtorStatus.notSelected'), 'status');
              return;
            }

            await showDialog({
              component: IDialogComponent.debtorStatus,
              payload: {
                productionType: module.value as ProductionType.judicial,
                debtorIds: selectedItems.map((i) => +i),
              },
            });
          },
        },
      ] as Array<ActiveTableAction<Debtor, 'pk'> | boolean>
    ).filter(Boolean) as Array<ActiveTableAction<Debtor, 'pk'>>)
    .map((action) => ({
      ...action,
      id: action?.id ?? `debtors_page_${action?.key}_table_action`,
    })));

  return {
    actions,
    isSettingsDialogVisible,
  };
};
