export const apiUrl = process.env.VUE_APP_API_URL as string;
export const standartizeApiUrl = process.env.ADMIN_API_URL as string || 'https://admin.api-asj.urrobot.net';
// process.env.VUE_APP_LOCAL_STANDARTIZE_URL;

export const socketURL = process.env.VUE_APP_WS_URL as string;

export const tinyApiKey = process.env.VUE_APP_TINY_API_KEY as string;

export const sentryDsn = process.env.VUE_APP_SENTRY_DSN as string;

export const reCaptchaApiKey = process.env.VUE_APP_RECAPTCHA_API_KEY as string;

export const isDev = process.env.NODE_ENV === 'development';

export const envIsDev = apiUrl === 'http://localhost:8080/api' || apiUrl === 'https://api-test.urrobot.net';

export const envIsMobile = !!(process.env.VUE_APP_IS_MOBILE as any);
