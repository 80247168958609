import { useInjectDebtorDialog } from '@/components/dialog/dialogs/debtor/useInjectDebtorDialog';
import { useModelDialog } from '@/hooks/useModelDialog';
import { ActiveFormFieldType } from '@/hooks/useActiveForm';
import { useDocumentsApi } from '@/hooks/useDocumentsApi';
import { ApiCommand } from '@/store/modules/api';
import { useProtectedDefaultCompany } from '@/hooks/useProtectedDefaultCompany';

type Model = {
  number: string;
  date: string;
  file: File;
}

type DocumentIdsToRemoveBeforeCreate = number[];

export const usePreloadedChargesModel = () => {
  const { debtorId, productionType } = useInjectDebtorDialog();
  const { companyId } = useProtectedDefaultCompany();

  const apiEndpoints = useDocumentsApi();

  return useModelDialog<Model, Model>({
    key: 'add_preloaded_charges',
    title: 'Добавление свода начислений',
    onModelUpdate: async (model) => {
      const errors = [];
      // if (!model.number) {
      //   errors.push(['number', 'Поле обязательно']);
      // }
      // if (!model.date) {
      //   errors.push(['date', 'Поле обязательно']);
      // }
      if (!model.file) {
        errors.push(['file', 'Поле обязательно']);
      }
      if (errors.length) {
        return {
          status: false,
          response: errors,
        };
      }

      const recordResponse = await apiEndpoints.fetchUniqueDebtorsDocuments(
        { limit: 1000, page: 1, filters: { debtor_id: debtorId.value, key: 'preloaded_charges' } },
      );

      if (!recordResponse.status) {
        return {
          status: false, response: [['detail', 'неизвестная ошибка']],
        };
      }

      await Promise.all(
        recordResponse.response.results.map(
          ({ id }: any) => apiEndpoints.removeDocument(
            ApiCommand.removeUniqueDebtorsDocument,
            {
              id,
              debtor_id: debtorId.value,
            },
          ),
        ),
      );

      return apiEndpoints.uploadUniqueDebtorsDocument({
        debtor: debtorId.value,
        production_type: productionType.value,
        file: model.file,
        number: model.number,
        date: model.date,
        key: 'preloaded_charges',
        estate_object: null,
        company: companyId.value,
      });
    },
    fields: [
      {
        key: 'number',
        field: 'number',
        type: ActiveFormFieldType.input,
        options: {
          label: 'Номер',
        },
      },
      {
        key: 'date',
        field: 'date',
        type: ActiveFormFieldType.date,
        options: {
          serializeValue: true,
          label: 'Дата составления',
        },
      },
      {
        key: 'file',
        field: 'file',
        type: ActiveFormFieldType.file,
        options: {
          label: 'Файл',
        },
      },
    ],
  });
};
