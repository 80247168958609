import { useLocalI18n } from '@/hooks/useLocalI18n';
import {
  computed, h, Ref, ref,
} from 'vue';
import { IDialogComponent, useDialog } from '@/hooks/useDialog';
import { useCourts } from '@/hooks/useCourts';
import {
  ActionType,
  ActiveTableAction,
  FetchFn,
  useActiveTable,
} from '@/components/activeTable/useActiveTable';
import StatusList from '@/components/dialog/dialogs/debtor/tabs/courts/StatusList.vue';
import { formatDateTime, formatDate, parseDateRU } from '@/utils/date';
import { SignalType, useSignal } from '@/hooks/useSignal';
import {
  useFetchCourtsCases,
} from '@/components/dialog/dialogs/debtor/tabs/courts/useFetchCourtsCases';
import { CourtCase, CourtTypeTabKey } from '@/components/dialog/dialogs/debtor/tabs/courts/types';
import { useFilters } from '@/components/dialog/dialogs/debtor/tabs/courts/useFilters';
import { useColumns } from '@/components/dialog/dialogs/debtor/tabs/courts/useColumns';

type Props = {
  activeTab: Ref<CourtTypeTabKey>;
  fetch: Ref<FetchFn<CourtCase> | undefined>;
}

export const useCourtsTable = ({ activeTab, fetch }: Props) => {
  const archive = !!fetch.value;
  const { t } = useLocalI18n('debtor.courts');
  const isLoading = ref<boolean>(false);
  const { filters } = useFilters(activeTab);

  const {
    removeCourtCase,
  } = useCourts();

  const { showDialog, confirmDialog } = useDialog();

  const onRowClick = async (row: any) => {
    if (row.record.type === 'court_case') {
      await showDialog({
        component: IDialogComponent.courtCaseDetails,
        addInRoute: false,
        payload: {
          courtCaseId: row.record.id,
        },
      });
    }
  };

  const {
    records,
    columns,
    actions,
    limit,
    page,
    total,
    fetchData: refetch,
  } = useActiveTable<CourtCase, CourtCase, 'id'>({
    keyField: 'id',
    defaultLimit: ref(100),
    fetch: fetch.value ?? useFetchCourtsCases(isLoading, archive),
    filters: filters(archive),
    columns: useColumns(),
    actions: computed(() => ([
      {
        key: 'remove',
        icon: 'trash',
        label: 'Удалить',
        types: [ActionType.record],
        handler: async ({ selectedItems }: { selectedItems: number[] }) => {
          const recordId = selectedItems[0] as number;
          if (!recordId) {
            return;
          }
          const record = records.value.find((rec) => rec.id === recordId);
          const confirmDialogResult = await confirmDialog({
            key: 'remove_court_case',
            title: 'Подтверждение удаления',
            message: 'Удалить запись судебного дела?',
            confirmLabel: 'Подтвердить',
            cancelLabel: 'Отмена',
          });
          confirmDialogResult.closeDialog();
          if (!confirmDialogResult.result) {
            return;
          }
          const response = await removeCourtCase(record.id as number);
          if (response.status) {
            await refetch();
          }
        },
      },
    ].map((action, i) => ({
      ...action,
      id: `modal_debtor_courts_tab_delete_action_${i}`,
    }))) as Array<ActiveTableAction<any, any>>),
  });

  const { subscribeUntilUnmount } = useSignal();
  subscribeUntilUnmount(SignalType.debtorCourtCasesUpdated, refetch);

  const showRecordLog = async (record: any) => {
    await showDialog({
      component: IDialogComponent.content,
      addInRoute: false,
      payload: {
        title: t('column.status_history'),
        content: h(StatusList, {
          items: record.log?.map(
            ({ date, doc_found, state }: { date: string; doc_found: string ;state: string }) => ({
              label: formatDate(parseDateRU(date)),
              description: [doc_found, state].join(' '),
            }),
          ) || [],
        }),
      },
    });
  };

  return {
    t,
    onRowClick,
    isLoading,
    activeTab,
    records,
    columns,
    actions,
    limit,
    page,
    total,
    formatDateTime,
    showRecordLog,
  };
};
