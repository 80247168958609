import { graphic } from 'echarts/core';
import { CHART_COLORS, DefaultLegendConfig, DefaultTooltipConfig } from '@/components/plate/utils';
import { envIsMobile } from '@/utils/env';

export enum ChartLineVariant {
  Linear = 'linear',
  // LinearFillArea = 'lineal-fill-area',
  // LinearSmooth = 'linear-smooth',
  // LinearSmoothFillArea = 'linear-smooth-fill-area',
  Polar = 'polar',
  // PolarFillArea = 'polar-fill-area'
}

export enum ChartLineSeriesVariant {
  Basic = 'basic',
  SmoothFillArea = 'smooth-fill-area',
  SmoothFillAreaGradient = 'smooth-fill-area-gradient',
  Smooth = 'smooth',
  Bar = 'bar',
}

export const chartLineSeriesVariantOptions = [
  {
    label: 'Стандартная',
    value: ChartLineSeriesVariant.Basic,
  },
  {
    label: 'Гладкая',
    value: ChartLineSeriesVariant.Smooth,
  },
  {
    label: 'Гладкая заполненная',
    value: ChartLineSeriesVariant.SmoothFillArea,
  },
  {
    label: 'Гладкая заполненная с градиентом',
    value: ChartLineSeriesVariant.SmoothFillAreaGradient,
  },
  {
    label: 'Столбцы',
    value: ChartLineSeriesVariant.Bar,
  },
];

export const LineChartBasicOptions = {
  tooltip: DefaultTooltipConfig,
  color: CHART_COLORS,
  dataset: {

  },
  legend: DefaultLegendConfig,
  grid: {
    top: '40px',
    left: '40px',
    right: envIsMobile ? '15px' : '35px',
    bottom: envIsMobile ? '140px' : '40px',
    containLabel: false,
  },
  splitLine: {
    show: true,
    lineStyle: {
      color: '#e8edf4',
    },
  },
  yAxis: {
    axisTick: false,
    axisLabel: {
      fontSize: '11px',
      align: 'left',
      color: 'rgba(94, 100, 118, 0.5)',
      fontWeight: 400,
      lineHeight: 21,
      margin: 40,
      formatter(value: any, index: any) {
        const formatter = (number: number) => {
          const formatterMap = {
            1000000000: 'млрд.',
            1000000: 'млн.',
            1000: 'тыс.',
          } as Record<number, string>;

          let divideTo = 1;
          let divided = number;
          while (divided >= 1000) {
            divideTo *= 1000;
            divided = number / divideTo;
          }
          const postfix = formatterMap[divideTo];
          return [divided.toFixed(0), postfix]
            .filter((v) => v !== null && v !== undefined)
            .join(' ');
        };
        return formatter(value);
      },
    },
  },
  xAxis: [
    {
      type: 'category',
      boundaryGap: false,
      axisTick: false,
      axisLine: false,
      axisLabel: {
        fontSize: '11px',
        color: 'rgba(94, 100, 118, 0.5)',
        fontWeight: 400,
        lineHeight: 21,
        margin: 15,
      },
    },
  ],
  series: [],
};

export const getLineChartLineSmoothFillAreaGradient = ({
  name,
  id,
  stack,
  encode,
  color,
}: any) => ({
  name,
  id,
  stack,
  encode,
  type: 'line',
  showSymbol: true,
  symbolSize: 7,
  smooth: true,
  lineStyle: {
    width: 1,
    opacity: 1,
    color: new graphic.LinearGradient(1.05, 0, -0.18474792, 1.0551131, [
      {
        offset: 0,
        color,
      },
      {
        offset: 0.38,
        color: `rgba(${color}, 0.38)`,
      },
      {
        offset: 1,
        color: `rgba(${color}, 0)`,
      },
    ]),
  },
  areaStyle: {
    color: new graphic.LinearGradient(0, -1.5, 0, 1, [
      {
        offset: 0,
        color,
      },
      {
        offset: 1,
        color: `rgba(${color}, 0)`,
      },
    ]),
  },
});

export const getLineChartFillArea = (
  {
    name,
    id,
    stack,
    encode,
    color,
  }: any,
) => ({
  name,
  id,
  stack,
  encode,
  type: 'line',
  showSymbol: true,
  symbolSize: 7,
  smooth: false,
  lineStyle: {
    width: 2,
    opacity: 1,
    color,
  },
  areaStyle: {
    opacity: 0.2,
    color,
  },
});

export const getLineChartSmooth = (
  {
    name,
    id,
    stack,
    encode,
    color,
  }: any,
) => ({
  name,
  id,
  stack,
  encode,
  type: 'line',
  showSymbol: true,
  symbolSize: 7,
  smooth: true,
  lineStyle: {
    width: 2,
    opacity: 1,
    color,
  },
});

export const getLineChartBasic = (
  {
    name,
    id,
    stack,
    encode,
    color,
  }: any,
) => ({
  name,
  id,
  stack,
  encode,
  type: 'line',
  showSymbol: true,
  symbolSize: 7,
  smooth: false,
  lineStyle: {
    width: 2,
    opacity: 1,
    color,
  },
});

export const getLineChartSmoothFillArea = (
  {
    name,
    id,
    stack,
    encode,
    color,
  }: any,
) => ({
  name,
  id,
  stack,
  encode,
  type: 'line',
  showSymbol: true,
  symbolSize: 7,
  smooth: true,
  lineStyle: {
    width: 2,
    opacity: 1,
    color,
  },
  areaStyle: {
    opacity: 0.2,
    color,
  },
});

export const getBarChart = (
  {
    name,
    id,
    stack,
    encode,
    color,
  }: any,
) => ({
  name,
  id,
  stack,
  encode,
  color,
  type: 'bar',
  // barGap: '-100%',
  barCategoryGap: '40%',
});

export const lineChartExample1 = {
  tooltip: DefaultTooltipConfig,
  legend: {
    show: false,
    orient: 'vertical',
    left: '0',
    align: 'left',
    // right: '100%',
    bottom: '0',
    icon: 'roundRect',
    width: '100%',
    // top: '80%',
  },
  grid: {
    top: '40px',
    left: '40px',
    right: '35px',
    bottom: '40px',
    containLabel: false,
  },
  splitLine: {
    show: true,
    lineStyle: {
      color: '#e8edf4',
    },
  },
  yAxis: {
    axisTick: false,
    axisLabel: {
      fontSize: '11px',
      align: 'left',
      color: 'rgba(94, 100, 118, 0.5)',
      fontWeight: 400,
      lineHeight: 21,
      margin: 40,
    },
  },
  xAxis: [
    {
      type: 'category',
      boundaryGap: false,
      axisTick: false,
      axisLine: false,
      axisLabel: {
        fontSize: '11px',
        color: 'rgba(94, 100, 118, 0.5)',
        fontWeight: 400,
        lineHeight: 21,
        margin: 15,
      },
    },
  ],
  series: {
    type: 'line',
    showSymbol: true,
    symbolSize: 7,
    smooth: true,
    lineStyle: {
      width: 1,
      opacity: 1,
      color: new graphic.LinearGradient(1.05, 0, -0.18474792, 1.0551131, [
        {
          offset: 0,
          color: '#5564F5',
        },
        {
          offset: 0.38,
          color: 'rgba(85, 100, 245, 0.38)',
        },
        {
          offset: 1,
          color: 'rgba(85, 100, 245, 0)',
        },
      ]),
    },
    areaStyle: {
      color: new graphic.LinearGradient(0, -1.5, 0, 1, [
        {
          offset: 0,
          color: '#5564F5',
        },
        {
          offset: 1,
          color: 'rgba(85, 100, 245, 0)',
        },
      ]),
    },
  },
};

const lineChartExample2 = {
  tooltip: DefaultTooltipConfig,
  color: CHART_COLORS,
  legend: {
    show: false,
    orient: 'vertical',
    left: '0',
    align: 'left',
    // right: '100%',
    bottom: '0',
    icon: 'roundRect',
    width: '100%',
  // top: '80%',
  },
  dataset: {
    source: [],
  },
  grid: {
    top: '40px',
    left: '40px',
    right: '35px',
    bottom: '40px',
    containLabel: false,
  },
  splitLine: {
    show: true,
    lineStyle: {
      color: '#e8edf4',
    },
  },
  yAxis: {
    axisTick: false,
    axisLabel: {
      fontSize: '11px',
      align: 'left',
      color: 'rgba(94, 100, 118, 0.5)',
      fontWeight: 400,
      lineHeight: 21,
      margin: 40,
    },
  },
  xAxis: [
    {
      type: 'category',
      boundaryGap: false,
      axisTick: false,
      axisLine: false,
      axisLabel: {
        fontSize: '11px',
        color: 'rgba(94, 100, 118, 0.5)',
        fontWeight: 400,
        lineHeight: 21,
        margin: 15,
      },
    },
  ],
  series: [],
};

export const getPreparedChart2EmptySeries = (
  {
    name,
    id,
    stack,
    encode,
  }: any, i: number,
) => ({
  name,
  id,
  stack,
  encode,
  type: 'line',
  showSymbol: true,
  symbolSize: 7,
  smooth: false,
  lineStyle: {
    width: 2,
    opacity: 1,
    color: CHART_COLORS[i],
  },
  areaStyle: {
    opacity: 0.2,
    color: CHART_COLORS[i],
  },
});

const getSeriesSmoothFillArea = (
  {
    name,
    id,
    stack,
    encode,
  }: any, i: number,
) => ({
  type: 'line',
  showSymbol: true,
  symbolSize: 7,
  smooth: true,
  name,
  id,
  stack,
  encode,
  lineStyle: {
    width: 1,
    opacity: 1,
    color: new graphic.LinearGradient(1.05, 0, -0.18474792, 1.0551131, [
      {
        offset: 0,
        // color: 'red',
        color: '#5564F5',
      },
      {
        offset: 0.38,
        color: 'rgba(85, 100, 245, 0.38)',
      },
      {
        offset: 1,
        color: 'rgba(85, 100, 245, 0)',
      },
    ]),
  },
  areaStyle: {
    color: new graphic.LinearGradient(0, -1.5, 0, 1, [
      {
        offset: 0,
        color: '#5564F5',
      },
      {
        offset: 1,
        color: 'rgba(85, 100, 245, 0)',
      },
    ]),
  },
});

const LINE_SERIES_VARIANTS = {
  [ChartLineVariant.Linear]: getSeriesSmoothFillArea,
  [ChartLineVariant.Linear]: (
    {
      name,
      id,
      stack,
      encode,
    }: any, i: number,
  ) => ({
    name,
    id,
    stack,
    encode,
    type: 'line',
    showSymbol: true,
    symbolSize: 7,
    smooth: false,
    lineStyle: {
      width: 2,
      opacity: 1,
      color: CHART_COLORS[i],
    },
    areaStyle: {
      opacity: 0.2,
      color: CHART_COLORS[i],
    },
  }),
} as { [key in ChartLineVariant]: any};

export const getLineSeriesByVariant = (
  variant: ChartLineVariant,
) => LINE_SERIES_VARIANTS[variant] || getSeriesSmoothFillArea;

export const LINE_CHART_VARIANTS = {
  [ChartLineVariant.Linear]: lineChartExample2,
  [ChartLineVariant.Linear]: lineChartExample1,
} as { [key in ChartLineVariant]: any};

export const getLineChartByVariant = (
  variant: ChartLineVariant,
) => LINE_CHART_VARIANTS[variant] || lineChartExample2;
