import { Duration } from 'luxon';

export const getProgressPageRemainingTile = (
  seconds: number | null,
) => (seconds === null || seconds === undefined
  ? ''
  : `Осталось: ${Duration.fromObject({ seconds }).toFormat('hh:mm:ss')}\n`);

export const getProgressPageSpeed = (
  speed: number | null,
) => (speed === null || speed === undefined
  ? ''
  : `Скорость: ${speed.toFixed(0)}/c`);

export const isStandartizeAdmin = (email: string) => [
  'ap@urrobot.net',
  'admin@urrobot.net',
  'nafedorov@internet.ru',
  'tech@urrobot.net',
  'vip-dacha86@yandex.ru',
].includes(email);
