import { BillingOperator } from '@/hooks/useBilling';

export const billingOperatorNames: Record<BillingOperator, string> = {
  [BillingOperator.mosvodocanal]: 'Мосводоканал',
  [BillingOperator.billing_online_solutions]: 'Биллинг Онлайн Решения',
  [BillingOperator.ais_gorod]: 'АИС Город',
  [BillingOperator.megapolis]: 'Мегаполис ЖКХ',
  [BillingOperator.pik]: 'SQL',
  [BillingOperator.asu]: 'АСУ ОДС',
};

export const billingLogos: Record<BillingOperator, string> = {
  [BillingOperator.mosvodocanal]: require('@/assets/images/i_mvk_logo.png'),
  [BillingOperator.billing_online_solutions]: require('@/assets/images/billing_online.svg'),
  [BillingOperator.ais_gorod]: require('@/assets/images/ais_gorod.png'),
  [BillingOperator.megapolis]: require('@/assets/images/megapolis.png'),
  [BillingOperator.pik]: require('@/assets/images/sql.png'),
  [BillingOperator.asu]: require('@/assets/images/asu.svg'),
};

export const monthSelectOptions = {
  type: 'month',
  format: 'MM.YYYY',
};
