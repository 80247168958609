import { getApiRequest } from '@core/service/serviceConstructor';
import * as TE from 'fp-ts/TaskEither';
import { pipe } from 'fp-ts/function';

const imobisService = getApiRequest({
  baseUrl: 'https://imobis.urrobot.net/v3',
  interceptor: {
    request: [
      async (config) => {
        if (['authorization'].some(
          (header) => !config.headers[header],
        )) {
          throw new Error('no_token');
        }
        return config;
      },
    ],
  },
});

export enum ImobisChannel {
  sms = 'sms',
}

export type ImobisApiError = {
  result: 'error'; desc: string|Record<string, string[]>;
}

export const isImobisApiError = (
  payload: any,
): payload is ImobisApiError => payload && (payload as ImobisApiError).result === 'error';

export const ImobisApi = {
  fetchSenders: ({
    token, channel = ImobisChannel.sms,
  }: { token: string; channel: ImobisChannel }) => pipe(
    imobisService<{ senders: string[] }, ImobisApiError>({
      url: '/senders',
      method: 'POST',
      data: {
        channel,
      },
      headers: {
        authorization: `Token ${token}`,
      },
    }),
    TE.map((r) => r.senders),
  )(),
};
