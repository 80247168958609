import { onBeforeUnmount, Ref, watch } from 'vue';
import { useSettingsCommon } from '@/pages/exchange/integration/dialogs/useSettingsCommon';
import { SignalType, useSignal } from '@/hooks/useSignal';

export enum SettingEnabledKey {
  aisGorod = 'ais_gorod',
  bor = 'DATAPOOL_KEYS',
  mosvodocanal = 'mosvodocanal',
  pochta_ru = 'pochta_ru',
  megapolis = 'megapolis',
  pik = 'pik',
  asu = 'asu',
}

export const useSettingEnabled = (companyId: Ref<number>, settingKey: SettingEnabledKey | string) => {
  const {
    hasSetting,
    fetchSetting,
  } = useSettingsCommon({
    companyId,
    settingKey,
    useOwner: false,
    isReadable: true,
  });

  const { subscribeToSignal } = useSignal();

  onBeforeUnmount(subscribeToSignal(SignalType.integrationServiceConnectionUpdated, fetchSetting));

  watch(hasSetting, (value) => {
    console.log(`setting ${settingKey} ${value ? 'enabled' : 'disabled'}`);
  });

  return hasSetting;
};
