import { IToast, IToastLevel } from '@/hooks/useToast';
import { DataFileStatus } from '@/types/datafile';
import { DataFileSocketMessage } from '@/types/socket';
import { PLUGIN_EXECUTION_ERROR_MESSAGE } from '@core/plugins/cryptoPro/utils';
import { IDialog, IDialogComponent } from '@/hooks/useDialog';
import { h } from 'vue';
import ExchangeImportPaymentOrdersResult
  from '@/pages/exchange/import/paymentOrdersResult/ExchangeImportPaymentOrdersResult.vue';
import { getSocketErrorMessages, hasSocketErrorMessage } from '@/utils/socket';
import { RouteLocationRaw } from 'vue-router';
import Router from '@/router';
import { Duration } from 'luxon';
import { SignalType } from '@/hooks/useSignal';
import { SocketSubscriber } from '@/store/modules/socket';
import { t } from '@/i18n';
import {
  CHECK_TASK_RESULT_RETRIES_COUNT,
  isToast,
  ProgressContext,
  ProgressPayload,
  UNKNOWN_TOAST_RESULT,
} from '@/store/modules/tasksProgress';
import { IntegrationDialog } from '@/pages/exchange/integration/dialogs/integrationDialogs';
import { signDocument } from '@/components/dialog/dialogs/print/localSign/signDocument';

const ERROR_TOAST_DEFAULT: IToast = {
  label: 'print.toast.failure',
  level: IToastLevel.danger,
  duration: 4000,
};

const ERROR_TOAST_ENFORCEMENTS: IToast = {
  label: 'print.toast.enforcementsFailure',
  level: IToastLevel.danger,
  duration: 4000,
};

export async function startPrintTask(
  context: ProgressContext,
  { task, restore }: ProgressPayload,
) {

  const {
    commit, state, dispatch, rootState, getters, rootGetters,
  } = context;

  console.log('startPrintTask', task, restore);

  const progressbars = [{
    key: 'progress',
    label: 'print.toast.progress',
    max: 0,
    current: 0,
    message: '',
    showMessageInsteadOfLabel: true,
  }];
  task.progressbars = progressbars;

  const SUCCESS_TOAST_DEFAULT: IToast = {
    label: 'pureLabel',
    message: 'exchange.import.form.toast.submit.successMessage',
    params: {
      label: task.label,
    },
    level: IToastLevel.success,
    duration: 4000,
  };

  let checkTaskStatusIntervalId;
  let unsubSocket: () => void;
  if (!restore) {
    commit('setTask', task);
  }
  const storedTask = state.tasks[task.key];

  const existProgressToast = rootState.layout.toasts.find((t) => t.id === task.toastId);

  let closeProgressToast: () => void;

  const resultFiles: string[] = [];
  const errorLog: Error[] = [];

  const result: boolean|IToast|undefined|'enforcements'|'CRYPTO_ERROR'|Error = await new Promise(async (resolve) => {
    let lastSocketMessageTime: number;

    if (storedTask.cert_thumbprint) {
      await dispatch('thirdParty/loadCryptoPro', null, { root: true });
      // @ts-ignore
      await (window.cadesplugin as Promise<unknown>);
      console.log('cadesplugin loaded');
    }

    const onClose = () => resolve(undefined);

    if (!existProgressToast) {
      closeProgressToast = await dispatch('layout/showToast', {
        message: 'print.toast.message',
        progressbars: getters.taskProgressbars[storedTask.key],
        level: IToastLevel.info,
        duration: null,
        onClose,
      }, { root: true });
      commit(
        'setTaskToastId',
        {
          taskKey: storedTask.key,
          toastId: (closeProgressToast as unknown as { id: string }).id,
        },
      );
    } else {
      commit('layout/updateToastById', {
        id: storedTask.toastId,
        progressbars: getters.taskProgressbars[storedTask.key],
        onClose,
      }, { root: true });
      closeProgressToast = () => dispatch('layout/closeToastById', task.toastId, { root: true });
    }

    if (restore) {
      let checkTaskResultCounter = 0;
      const checkTaskResult = async () => {
        checkTaskResultCounter += 1;
        if (checkTaskResultCounter > CHECK_TASK_RESULT_RETRIES_COUNT && !lastSocketMessageTime) {
          resolve(UNKNOWN_TOAST_RESULT);
        } else {
          const taskResult = await dispatch('fetchPrintTaskResult', task.id);
          if (taskResult) {
            const { status, result } = taskResult;
            if (status === DataFileStatus.FAILED) resolve(false);
            else if (status === DataFileStatus.PROCESSED) {
              result?.url && resultFiles.push(result.url);
              result?.urlJson && resultFiles.push(result.urlJson);
              resolve(true);
            }
          }
        }
      };
      checkTaskStatusIntervalId = setInterval(() => {
        checkTaskResult();
      }, 10000);
    }

    let currentDocId: null | number = null;

    unsubSocket = await dispatch('socket/subscribe', {
      condition: (payload) => (
        (
          // @ts-ignore
          payload.action === 'progress_event'
          && (payload.data.event === `document_attachments/generate_merged/${task.id}/`
            || payload.data.event === `datafile/status/?id=${task.id}`)
        ) || (
          payload.action === 'model_event'
          && payload.data.model === 'document_attachments/status/update'
          && payload.data?.obj.id === task.id
        )
      ),
      handler: async (payload: DataFileSocketMessage) => {
        console.log('print task socket message', payload.data);

        lastSocketMessageTime = new Date().getTime();

        if (!currentDocId && payload.data.waited_gds?.length) {
          currentDocId = payload.data.waited_gds[0];
          signDocument(
            context,
            currentDocId,
            storedTask.cert_thumbprint!,
            (err) => {
              errorLog.push(err);
            },
            true,
          ).then((result) => {
            console.log('sign  result', result, errorLog);
            currentDocId = null;
            if (errorLog.some((error) => error.message?.toLowerCase()?.includes(PLUGIN_EXECUTION_ERROR_MESSAGE.toLowerCase()))) {
              resolve(new Error('Ошибка работы плагина КриптоПро'));
            }
          });
        }

        if (payload.data.report_id) {
          const { report_id: reportId } = payload.data;
          await dispatch(
            'fetchTaskReportUrl',
            { taskKey: storedTask.key, description: 'Отчёт', reportId },
          );
        }

        if (payload.data?.obj?.report) {
          const report = payload.data.obj.report;

          await dispatch(
            'layout/showDialog',
            {
              component: IDialogComponent.contentSmall,
              payload: {
                title: 'Результат загрузки',
                content: h(ExchangeImportPaymentOrdersResult, {
                  report,
                  errorFiles: payload.data?.obj?.archive,
                }),
              },
              addInRoute: false,
            } as IDialog,
            { root: true },
          );

          if (payload.data?.obj?.error) {
            await dispatch('layout/showToast', {
              level: IToastLevel.danger,
              label: 'pureLabel',
              message: 'pure',
              params: {
                label: 'Ошибка загрузки',
                message: payload.data?.obj?.error,
              },
            } as IToast, { root: true });
          }

          if (payload.data?.obj?.is_success !== undefined) {
            resolve(
              payload.data?.obj?.is_success
                ? SUCCESS_TOAST_DEFAULT
                : ERROR_TOAST_ENFORCEMENTS,
            );

            return;
          }
        }

        if ('error_message' in payload.data || 'error' in payload.data) {
          const NO_CRYPTO_ERROR = 'Ошибка подписи документов должников';
          if (payload.data.description?.toLowerCase().includes(
            NO_CRYPTO_ERROR.toLowerCase(),
          )) {
            if ([
              'У вас есть другая неподтверждённая операция. Сначала подтвердите/отмените её, затем повторите действия.',
              'ApiV3 не позволяет подписывать пустой контент. Операция не поддерживается.',
            ].some((msg) => hasSocketErrorMessage(payload.data.error_message, msg))
            ) {
              await dispatch('layout/showToast', {
                label: 'pureLabel',
                message: 'pure',
                duration: 10000,
                params: {
                  label: 'Ошибка подписания по ЭЦП',
                  message: payload.data.error_message,
                },
                level: IToastLevel.danger,
              }, { root: true });
              resolve('CRYPTO_ERROR');
              console.log('socket message return', payload.data);
              return;
            }
          }
          await dispatch('layout/showToast', {
            label: 'pureLabel',
            message: 'pure',
            duration: 10000,
            params: {
              // @ts-ignore
              label: payload.data.description,
              message: getSocketErrorMessages(payload.data.error_message ?? payload.data.error),
            },
            level: IToastLevel.danger,
          }, { root: true });
        }
        if (payload.data?.obj?.status === 3) {
          const errorMessage = payload.data?.obj.status_name;
          await dispatch('layout/showToast', {
            label: 'pureLabel',
            duration: 10000,
            params: {
              label: errorMessage,
            },
            level: IToastLevel.danger,
          }, { root: true });
        }
        if (payload.data?.obj?.error) {
          let pathToRoute : RouteLocationRaw | null = null;
          if (payload.data.obj.error_code === 'esia_required') {
            pathToRoute = `/exchange/integration/services#${JSON.stringify({
              component: IntegrationDialog.integrationGosuslugi,
              addInRoute: true,
              preventCloseOnRouteChange: true,
              payload: { companyId: rootGetters['companies/defaultCompanyId'] },
            })}`;
          }
          await dispatch('layout/showToast', {
            level: IToastLevel.danger,
            label: 'pureLabel',
            message: 'pure',
            duration: null,
            isCloseable: true,
            onClick: pathToRoute ? () => Router.push(pathToRoute as RouteLocationRaw) : undefined,
            params: {
              label: 'Ошибка загрузки',
              message: `${payload.data.obj.error}${pathToRoute ? ' (нажмите, чтобы перейти)' : ''}`,
            },
          } as IToast, { root: true });

          if (pathToRoute) {
            await Router.push(pathToRoute as RouteLocationRaw);
          }
          if (payload.data?.obj?.is_success !== undefined) {
            resolve(
              payload.data?.obj?.is_success
                ? SUCCESS_TOAST_DEFAULT
                : ERROR_TOAST_ENFORCEMENTS,
            );
            return;
          }
        }

        let progressMessage = '';
        let maxProgress: number|undefined;
        let currentProgress: number|undefined;
        // subprogress
        let subProgressMessage = '';
        let maxSubProgress: number|undefined;
        let currentSubProgress: number|undefined;

        let signingMessage = '';
        let maxSigningProgress: number|undefined;
        let currentSigningProgress: number|undefined;
        // Показываем таймер при облачном подписании
        // let isShowECPTimer = !task.cert_thumbprint && payload.data.description && compareStrings(payload.data.description, 'Подписание документов по ЭЦП');
        const ECP_TIMER_SECONDS = 900; // 15 минут
        let isShowECPTimer = false;
        let remainingTimeFormatted;

        if (payload.action === 'progress_event') {

          console.log('handle print task progress_event', payload.data);
          const subprogress = payload.data?.sub_progresses?.[0];
          const remainingTime = subprogress?.remaining_time ?? null;

          remainingTimeFormatted = remainingTime
            ? Duration.fromDurationLike({
              second: remainingTime,
            }).toFormat('mm:ss')
            : null;

          if (subprogress) {
            console.log('set subprogress', payload.data);
            const { step, total } = subprogress;
            subProgressMessage = `Количество: ${step} из ${total}`;
            maxSubProgress = total;
            currentSubProgress = step;
          }

          if (payload.data.progress) {
            maxProgress = payload.data.progress.total;
            currentProgress = payload.data.progress.step;
          }

          if (payload.data.description) {
            const documentsHaveBeenFormedCleanMessage = 'Документы по должнику сформированы';
            const cleanMessage = payload.data.description.replace(
              /\sid=\d+/, '',
            );
            if (cleanMessage === documentsHaveBeenFormedCleanMessage) {
              progressMessage = `Формирование документов:\n${payload.data.progress.step} из ${payload.data.progress.total}`;
            } else {
              progressMessage = cleanMessage;
            }
          }

          if (payload.data.description === 'Подготовка и загрузка в УЦ') {
            progressMessage += ` (должников: ${payload.data.debtor_total})`;

          } else if (payload.data.description === 'Подписание документов по ЭЦП') {
            const { encrypt_step, encrypt_total } = payload.data;
            if (encrypt_step === 0 && !task.cert_thumbprint) {
              progressMessage = 'Ожидание подтверждения подписания по ЭЦП от пользователя';
              isShowECPTimer = true;
            }
            if (encrypt_step) {
              isShowECPTimer = false;
            }
            signingMessage += `подписано ${encrypt_step} из ${encrypt_total}`;
            maxSigningProgress = encrypt_total;
            currentSigningProgress = encrypt_step;
          }

        } else {
          currentProgress = storedTask.progressbars[0].max;

          const { file_pdf, file, file_json } = payload?.data?.obj || {};
          if (file || file_pdf || file_json) {
            file && resultFiles.push(file);
            file_pdf && resultFiles.push(file_pdf);
            file_json && resultFiles.push(file_json);
          } else {
            // await dispatch('layout/showToast', ERROR_TOAST_DEFAULT, { root: true });
          }
        }
        console.log('subprogress', subProgressMessage, currentSubProgress, maxSubProgress);

        commit('updateTask', {
          key: storedTask.key,
          progressbars: [{
            key: storedTask.progressbars[0].key,
            max: maxProgress,
            current: currentProgress,
            message: progressMessage,
          },
          ...(maxSubProgress && !maxSigningProgress ? [{
            key: 'debtor_document_counter',
            label: '',
            max: maxSubProgress,
            current: currentSubProgress,
            message: subProgressMessage,
            showMessageInsteadOfLabel: true,
          }] : (maxSigningProgress ? [{
            key: 'debtor_document_counter',
            label: '',
            max: maxSigningProgress,
            current: currentSigningProgress,
            message: signingMessage,
            showMessageInsteadOfLabel: true,
          }] : [])),
          ],
          params: {
            remainingTime: remainingTimeFormatted,
          },
        });
        commit('layout/updateToastById', {
          id: storedTask.toastId,
          progressbars: getters.taskProgressbars[storedTask.key],
          ...(
            remainingTimeFormatted
              ? {
                message: 'print.toast.messageRemaining',
                params: {
                  remainingTime: remainingTimeFormatted,
                },
              }
              : {}
          ),
          ...(
            isShowECPTimer
              ? { countdown: ECP_TIMER_SECONDS } : { countdown: null }
          ),
        }, { root: true });

        if (payload.data.done) {
          commit('layout/signal', { signalType: SignalType.debtorsUpdated }, { root: true });
          if (!payload.data.error && !payload.data.error_message) {
            resolve(payload.data.event === `datafile/status/?id=${task.id}` ? 'enforcements' : true);
          } else {
            resolve(false);
          }
        }
      },
    } as SocketSubscriber<DataFileSocketMessage>, { root: true });
  });

  console.log('PrintTask: error log', errorLog);

  if (errorLog.length) {
    await dispatch('layout/showToast', {
      level: IToastLevel.danger,
      label: 'Отчет ошибок физической подписи документов',
      message: errorLog.map((error) => error.message).join('\n'),
    } as IToast, { root: true });
  }

  // ждем загрузки всех отчетов
  await new Promise<void>(async (resolve) => {
    if (!state.tasksFetchingPromises[task.key]) {
      resolve();
    } else {
      await Promise.all(state.tasksFetchingPromises[task.key]);
      resolve();
    }
  });

  const hasReports = storedTask.reports?.length;

  console.log('printTaskResult', result);

  if (result !== undefined) {
    if (result) {
      if (isToast(result)) {
        await dispatch('layout/showToast', UNKNOWN_TOAST_RESULT, { root: true });
      } else if (result instanceof Error) {
        await dispatch('layout/showToast', {
          title: 'Ошибка физической подписи ЭЦП',
          message: result.message,
          level: IToastLevel.danger,
          duration: 5000,
        } as IToast, { root: true });
      } else {
        await dispatch('layout/showToast', {
          message: result === 'enforcements' ? 'print.toast.enforcements' : 'print.toast.success',
          level: IToastLevel.success,
          duration: 5000,
        }, { root: true });
      }
      if (resultFiles.length) {
        // const fileExtension = getFileExtension(resultFile);
        await dispatch('layout/showDialog', {
          component: IDialogComponent.file,
          addInRoute: false,
          payload: {
            title: t('print.dialog.title'),
            url: '',
            urls: resultFiles,
            withPreview: !storedTask.encrypt,
            withCopy: !storedTask.encrypt,
            reports: storedTask.reports,
          },
        }, { root: true });
      } else if (hasReports) {
        await dispatch('layout/showDialog', {
          component: IDialogComponent.file,
          addInRoute: false,
          payload: {
            title: t('print.dialog.title'),
            reports: storedTask.reports,
          },
        }, { root: true });
      }
    } else if (hasReports) {
      await dispatch('layout/showDialog', {
        component: IDialogComponent.contentSmall,
        payload: {
          title: 'Отчёт об ошибках',
          content: h(ExchangeImportPaymentOrdersResult, {
            report: storedTask.reports![0]!.url,
          }),
        },
        addInRoute: false,
      } as IDialog, { root: true });
    }
  } else {
    await dispatch('layout/showToast', ERROR_TOAST_DEFAULT, { root: true });
  }
  commit('removeTask', task);
  // @ts-ignore
  unsubSocket?.();
  // @ts-ignore
  closeProgressToast?.();
  clearInterval(checkTaskStatusIntervalId);
  return true;
}
