import { computed, h, Ref } from 'vue';
import { ActiveFormField, ActiveFormFieldType, IRadio } from '@/hooks/useActiveForm';
import { ISelectInput } from '@/components/selectInput/useSelectInput';
import { getDisplayValues } from '@/components/selectInput/SelectInput.vue';
import NA from '@/components/na/NA.vue';
import { formatDate } from '@/utils/date';
import { ICheckbox } from '@/components/checkbox/useCheckbox';
import { ActiveTableColumn, ActiveTableColumnFormat } from '@/components/activeTable/useActiveTable';
import { valueFormattersMap } from '@/components/activeTable/valueFormattersMap';

type ActiveFieldLabelConfig = {
  value: Ref<any>;
  format?: Ref<ActiveTableColumn<any>['format']>;
  renderValue: Ref<((props: any) => any)|undefined>;
  isReadonly: Ref<boolean>;
  type: Ref<ActiveFormFieldType>;
  options: Ref<ActiveFormField<any>['options']>;
}

export const useActiveFieldLabel = ({
  value,
  format,
  renderValue,
  isReadonly,
  type,
  options,
}: ActiveFieldLabelConfig) => {
  /**
   * Значение select, отображаемое в режиме readOnly
   */
  const selectLabel = computed(
    () => {
      if (!isReadonly.value) return undefined;
      if (type.value !== ActiveFormFieldType.select) return undefined;
      const {
        valueIsObj, displayField, valueField, renderLabel, options: selectOptions,
      } = options.value as ISelectInput<any>;
      return getDisplayValues(
        value.value,
        valueIsObj || false,
        displayField,
        valueField,
        renderLabel,
        selectOptions,
      ).join(', ');
    },
  );

  /**
   * Значение отображаемое в режиме readOnly
   */
  const renderedValue = computed(() => {
    const _type = type.value;
    const _value = value.value;
    if (_type === ActiveFormFieldType.select) {
      if (![null, undefined].includes(selectLabel.value as any)) {
        return selectLabel.value;
      }
      return h(NA);
    }

    if (_value === null || value === undefined) {
      return h(NA);
    }

    if (_type === ActiveFormFieldType.date) {
      return formatDate(_value as Date, 'ru');
    }

    if (_type === ActiveFormFieldType.dateRange) {
      const _value_daterange = _value as [Date, Date]|[string|string];
      if (_value_daterange.length !== 2) return h(NA);
      return _value_daterange.map((d) => formatDate(d, 'ru')).join(' - ');
    }

    if (_type === ActiveFormFieldType.checkbox) {
      const label = (options as ICheckbox).label || 'Да';
      const falseLabel = (options as ICheckbox).falseLabel || 'Нет';
      return _value === true ? label : falseLabel;
    }

    if (_type === ActiveFormFieldType.radio) {
      // @ts-ignore
      const label = (options.value as IRadio)?.options.find(
        (opt) => opt.value === _value,
      )?.label || _value;
      return label;
    }

    if (!renderValue?.value && !_value) {
      return h(NA);
    }

    const val = format?.value
      ? typeof format?.value === 'function'
        ? format.value(_value)
        : valueFormattersMap[format?.value](_value, 'ru')
      : _value;
    return renderValue?.value ? renderValue.value(val) : val;
  });

  return renderedValue;
};
