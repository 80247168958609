import { Debtor, DebtorSubstatus, MetaOfDebtorStatus } from '@/hooks/useDebtors';
import { CompanyModuleType } from '@/pages/debtors/_module/useDebtorsActions';
import { ProductionType } from '@/hooks/useConstructor';
import { formatDate } from '@/utils/date';
import { t } from '@/i18n';
import { parseDateStringWithDefaultFormat } from '@/components/dateInputNext/utils';
import { DateTime } from 'luxon';
import { DebtorIcon } from '@/store/modules/debtors';
import { getBankruptLabel } from '@/store/modules/debtors/icons/bankruptData';

export const getDebtorIcons = (
  debtor: Debtor,
  statusesMap: Record<any, any>,
  substatusesMap: Record<any, any>,
  companyModuleType: CompanyModuleType,
  productionType: ProductionType,
  companyHasBilling: boolean,
) => {

  const substatusesSortedByUpdatedAt = debtor.debtor.debtor_status.reduce((acc, { substatus }) => {
    if (substatus) {
      acc.push(...substatus);
    }
    return acc;
  }, [] as DebtorSubstatus[]).sort((a, b) => (a.updated_at < b.updated_at ? 1 : -1));
  const meta = substatusesSortedByUpdatedAt.reduce((
    acc,
    substatus,
  ) => {
    const substatusName = substatus.substatus;
    acc.substatuses.push(substatusName);
    if (substatusName.includes('sms') && acc.smsStatus === null) {
      acc.smsStatus = substatusName;
    } else if (substatusName.includes('voice') && acc.voiceStatus === null) {
      acc.voiceStatus = substatusName;
      if (typeof substatus.data !== 'string') {
        if (substatus.data?.text?.result) {
          acc.voiceStatusText = substatus.data?.text?.result;
        }
      }
    } else if (substatusName.includes('email')) {
      acc.emailStatus = substatusName;
    } else if (substatusName.includes('fees_paid')) {
      acc.isFeesPaid = true;
    } else if (substatusName.includes('fees_await_paid')) {
      acc.isFeesAwaitPaid = true;
    } else if (substatusName.includes('statement_ordered')) {
      acc.isStatementOrdered = true;
    } else if (substatusName.includes('statement_ready')) {
      acc.isStatementReady = true;
    } else if (substatusName.includes('statement_error')) {
      acc.isStatementError = true;
    } else if (substatusName.includes('statement_received')) {
      acc.isStatementReceived = true;
    } else if (substatusName.includes('cadnum_not_found')) {
      acc.isCadnumNotFound = true;
    } else if (substatusName.includes('fees_shareholders_await_paid')) {
      acc.isFeesShareholdersAwaitPaid = true;
    } else if (substatusName.includes('fees_shareholders_paid')) {
      acc.isFeesShareholdersPaid = true;
    } else if (substatusName.includes('cadnum_no_rights')) {
      acc.isCadnumHasNoRights = true;
    }
    return acc;
  }, {
    substatuses: [],
    smsStatus: null,
    voiceStatus: null,
    voiceStatusText: null,
    emailStatus: null,
    isFeesAwaitPaid: false,
    isFeesAwaitRepaid: false,
    isFeesShareholdersAwaitPaid: false,
    isFeesShareholdersPaid: false,
    isFeesPaid: false,
    isStatementError: false,
    isStatementOrdered: false,
    isStatementReady: false,
    isCadnumNotFound: false,
    isCadnumHasNoRights: false,
    isStatementReceived: false,
    isReadyToCourt: false,
  } as MetaOfDebtorStatus);

  if (debtor.debtor.debtor_status.some(({ status }) => status === 'ready_to_court')) {
    meta.isReadyToCourt = true;
  }

  const statementSubstatuses = [
    'estate_owner_unknown', 'statement_ready', 'statement_ordered', 'statement_received', 'statement_error',
    'cadnum_not_found', 'cadnum_no_rights', 'cadnum_not_verified', 'cadnum_dont_match', 'cadnum_archive',
  ];

  // если последний подстатус = "statement_error", то нужно показывать предыдущий статус ошибки (если есть)
  // т.к. statement_error - статус неинформативный
  const statementVerboseErrorSubstatuses = [
    'estate_owner_unknown',
    'cadnum_not_found', 'cadnum_no_rights', 'cadnum_not_verified', 'cadnum_dont_match', 'cadnum_archive',
  ];

  const statementColorsMap = {
    red: [
      'cadnum_not_found', 'cadnum_no_rights', 'estate_owner_unknown', 'cadnum_not_verified', 'cadnum_archive', 'statement_error',
    ],
    blue: ['statement_ordered', 'statement_received'],
    green: ['statement_ready'],
  };

  const statementColorsMapReversed = Object.fromEntries(
    Object.entries(statementColorsMap).reduce((acc, [key, statuses]) => {
      statuses.forEach((status) => {
        acc.push([status, key]);
      });
      return acc;
    }, [] as [string, string][]),
  );

  const actualStatementSubstatusIndex = substatusesSortedByUpdatedAt.findIndex(
    (substatus) => statementSubstatuses.includes(substatus.substatus),
  );

  const actualStatementSubstatus = substatusesSortedByUpdatedAt[actualStatementSubstatusIndex];

  const actualStatementVerboseErrorSubstatusIndex = substatusesSortedByUpdatedAt.findIndex(
    (substatus) => statementVerboseErrorSubstatuses.includes(substatus.substatus),
  );

  const actualStatementVerboseErrorSubstatus = substatusesSortedByUpdatedAt[actualStatementVerboseErrorSubstatusIndex];

  let actualStatementIcon;
  if (actualStatementSubstatus) {
    const finalSubstatus = actualStatementSubstatus.substatus === 'statement_error' && actualStatementVerboseErrorSubstatus
      ? actualStatementVerboseErrorSubstatus
      : actualStatementSubstatus;
    actualStatementIcon = {
      key: 'statement_status',
      icon: 'egrn-excerpt',
      color: statementColorsMapReversed[finalSubstatus.substatus],
      label: substatusesMap[finalSubstatus.substatus],
    };
  }
  // console.log('actualStatementIcon', actualStatementIcon);

  const isFeesAwaitRepaidIndex = substatusesSortedByUpdatedAt.findIndex((substatus) => substatus.substatus === 'fees_await_repaid');
  const isFeesPaidIndex = substatusesSortedByUpdatedAt.findIndex((substatus) => substatus.substatus === 'fees_paid');
  const isFeesAwaitPaidIndex = substatusesSortedByUpdatedAt.findIndex((substatus) => substatus.substatus === 'fees_await_paid');
  const isFeesShareholdersPaidIndex = substatusesSortedByUpdatedAt.findIndex((substatus) => substatus.substatus === 'fees_shareholders_paid');

  const icons = (([
    debtor.debtor_main_profile?.enrichment_date && {
      key: 'is_enrichment',
      icon: 'update',
      color: debtor.debtor_main_profile.enrichment_success ? 'green' : 'red',
      label: `${debtor.debtor_main_profile.enrichment_success ? 'Обогащено' : 'Данные не найдены'} (${formatDate(debtor.debtor_main_profile.enrichment_date)})`,
    },
    meta.voiceStatus && {
      key: 'voice',
      icon: 'voice',
      color: ['voice_delivered', 'voice_ready'].includes(meta.voiceStatus) ? 'green' : 'gray',
      label: meta.voiceStatusText
        ? meta.voiceStatusText
        : t('debtorIcon.voice', { status: substatusesMap[meta.voiceStatus] }),
    },
    meta.smsStatus && {
      key: 'sms',
      icon: 'sms',
      color: ['sms_notsend'].includes(meta.smsStatus) ? 'gray' : 'green',
      label: t('debtorIcon.sms', {
        status: substatusesMap[meta.smsStatus],
      }),
    },
    meta.emailStatus && {
      key: 'email',
      icon: 'envelope',
      color: ['email_delivered'].includes(meta.emailStatus) ? 'green' : 'gray',
      label: t('debtorIcon.email', {
        status: substatusesMap[meta.emailStatus],
      }),
    },
    isFeesAwaitRepaidIndex > -1 && (isFeesAwaitRepaidIndex < isFeesPaidIndex || isFeesPaidIndex === -1) && {
      key: 'fees_await_repaid',
      icon: 'coins',
      color: 'gray',
      label: substatusesMap.fees_await_repaid,
    },
    isFeesPaidIndex > -1 && (isFeesPaidIndex < isFeesAwaitRepaidIndex || isFeesAwaitRepaidIndex === -1) && {
      key: 'fees_paid',
      icon: 'coins',
      color: 'green',
      label: substatusesMap.fees_paid,
    },
    isFeesAwaitPaidIndex > -1
    && ((isFeesPaidIndex === -1 || isFeesAwaitPaidIndex < isFeesPaidIndex)
      && (isFeesShareholdersPaidIndex === -1 || isFeesAwaitPaidIndex < isFeesShareholdersPaidIndex)
      && (isFeesAwaitRepaidIndex === -1 || isFeesAwaitPaidIndex < isFeesAwaitRepaidIndex)
    ) && {
      key: 'fees_await_paid',
      icon: 'coins',
      color: 'gray',
      label: substatusesMap.fees_await_paid,
    },
    // не показывать эту иконку, если уже отображается "пошлина ожидает оплату"
    (!meta.isFeesShareholdersPaid && !meta.isFeesPaid) && !meta.isFeesPaid && !meta.isFeesAwaitPaid && meta.isFeesShareholdersAwaitPaid && {
      key: 'fees_shareholders_await_paid',
      icon: 'coins',
      color: 'gray',
      label: substatusesMap.fees_shareholders_await_paid,
    },
    // не показывать эту иконку, если уже отображается "пошлина ожидает оплату"
    meta.isFeesShareholdersPaid && !meta.isFeesAwaitPaid && {
      key: 'fees_shareholders_paid',
      icon: 'coins',
      color: 'green',
      label: substatusesMap.fees_shareholders_paid,
    },
    companyModuleType !== CompanyModuleType.Vehicles && actualStatementIcon && {
      ...actualStatementIcon,
    },
    meta.isReadyToCourt && (!debtor.tracker_court_types || debtor.tracker_court_types.length === 0) && {
      key: 'ready_to_court',
      icon: 'file-check',
      color: 'green',
      label: statusesMap.ready_to_court,
    },
    debtor.complaint_status === 'Зарегистрировано' && {
      key: 'complaint_status',
      icon: 'complaint-registered',
      color: 'green',
      label: 'Жалоба зарегистрирована',
    },
    debtor.complaint_status === 'Передан на судебный участок' && {
      key: 'complaint_status',
      icon: 'complaint-filed',
      color: 'green',
      label: 'Ходатайство передано на судебный участок',
    },
    debtor.complaint_status === 'Документ приобщён к судебному делу' && {
      key: 'complaint_status',
      icon: 'complaint-filed',
      color: 'green',
      label: 'Жалоба приобщена к делу',
    },
    debtor.bankrupts_data && debtor.bankrupts_data.length > 0 && {
      key: 'bankrupt_status',
      icon: 'bankrupt',
      color: 'red',
      label: getBankruptLabel(debtor),
    },
    debtor.debtor && debtor.debtor.debtor_status && debtor.debtor.debtor_status.some(
      (s) => s.substatus.some((ss) => ss.substatus === 'partially_moved_into_executive'),
    ) && {
      icon: 'to-executive',
      color: 'gray',
      label: 'Частично перенесен в ИП',
    },
    debtor.tracker_court_types && debtor.tracker_court_types.indexOf('mirsud') !== -1 && {
      icon: 'magistrate-court',
      color: 'gray',
      label: 'Подано в мировой суд',
    },
    debtor.tracker_court_types && debtor.tracker_court_types.indexOf('rajsud') !== -1 && {
      icon: 'regional-court',
      color: 'gray',
      label: 'Подано в районный суд',
    },
    debtor.is_decision_inexpedient && {
      icon: 'check-round',
      color: 'blue',
      label: 'Заключение о нецелеособразности',
    },
  ] as DebtorIcon[])
    .filter(Boolean) as Array<DebtorIcon>);

  if (companyHasBilling) {
    const {
      billing_download, billing_upload,
    } = debtor;
    const badBilling = !billing_upload;

    if ((billing_download || billing_upload)) {
      const parseAndFormatDate = (d: string) => DateTime.fromJSDate(new Date(d)).toFormat('dd.LL.yyyy HH:mm');
      const label = [
        billing_download ? `Получен: ${parseAndFormatDate(billing_download)}` : null,
        billing_upload ? `Отправлен: ${parseAndFormatDate(billing_upload)}` : null,
      ].filter(Boolean).join('\n');

      let icon: string;
      if (billing_download && billing_upload) {
        icon = 'billing-in-out';
      } else if (billing_download) {
        icon = 'billing-in';
      } else {
        icon = 'billing-out';
      }

      icons.push({
        key: 'billing',
        icon,
        color: badBilling ? 'red' : 'blue',
        label: badBilling ? `Проблемы биллинга:\n${label}` : `Данные биллинга:\n${label}`,
      });
    }
  }

  return {
    icons, meta,
  };
};
