import { Vehicle, VehicleTrip } from '@/hooks/useDebtors';
import { CompanyModuleType } from '@/pages/debtors/_module/useDebtorsActions';

export const Accruals = 'accruals'; // начислено
export const Debts = 'debts'; // задолженность
export const PaidUps = 'paid-ups'; // оплачено
export const Recalculations = 'recalculations'; //
export const Penalties = 'penalties'; //
export const Fee = 'fee'; // пеня
export const IncomeSource = 'incomeSource';

export const AllServices = 'Все услуги';

export const Saldo = 'Входящее сальдо';

export type FinanceTabKey =
  typeof Accruals
  | typeof Debts
  | typeof PaidUps
  | typeof Penalties
  | typeof Recalculations
  | typeof Fee;

export type ActiveTabKey = FinanceTabKey | typeof IncomeSource;

export const isIncomeSourceTab = (tab: ActiveTabKey) => tab === IncomeSource;

export type FilterTabsAllInValueType = typeof AllServices;
export type FilterTabValue = FilterTabsAllInValueType | string;
export type FilterTabsValues = FilterTabValue[];

export type ActiveServiceTab = string;

export type FullFinanceData = Record<ActiveTabKey, any>;

export type GetFinanceDataCommon = {
  activeTab: FinanceTabKey;
  financeData: DebtorFinanceData;
  companyModuleType: CompanyModuleType;
}

type DebtorFinanceDataFinanceRecordPart = {
  finance_data: number | null;
  id: number | null;
  title: string;
  value: number | string;
}

export type DebtorFinanceDataRecord = {
  date: string;
  parts: DebtorFinanceDataFinanceRecordPart[];
  start_date?: string;
  end_date?: string;
  value: number;
  prev_value: string;
  trip?: VehicleTrip;
}

export type DebtorFinanceData = {
  pk: number;
  accruals_data: {
    data: DebtorFinanceDataRecord[];
    columns: string[];
  };
  debts_data: DebtorFinanceDataRecord[];
  paid_ups_data: {
    data: DebtorFinanceDataRecord[];
    columns: string[];
  };
  recalculations_data: {
    data: DebtorFinanceDataRecord[];
    columns: string[];
  };
  penalties_data: DebtorFinanceDataRecord[];
  vehicle: Vehicle;
}

export type FinanceTabModel = {
  serviceFilterValue: ActiveServiceTab;
}
