import { useInjectDebtorDialog } from '@/components/dialog/dialogs/debtor/useInjectDebtorDialog';
import { useLocalI18n } from '@/hooks/useLocalI18n';
import {
  computed, onMounted, ref, watch,
} from 'vue';
import { ITab } from '@/components/tabs/useTabs';
import { IDialogComponent, useDialog, useDialogRouteParam } from '@/hooks/useDialog';
import { useDocumentsApi } from '@/hooks/useDocumentsApi';
import {
  ActionType,
  ActiveTableAction, ActiveTableColumn,
  ActiveTableColumnFormat,
  useActiveTable,
} from '@/components/activeTable/useActiveTable';
import { getAuthProtectedFileUrl, openAuthProtectedFile } from '@/utils/fileUrl';
import { OrderDirection } from '@/store/modules/api';
import { ActiveFormField, ActiveFormFieldType } from '@/hooks/useActiveForm';
import { DictType, useDicts } from '@/hooks/useDicts';
import { useCustomLocales } from '@/components/dialog/dialogs/debtor/useCustomLocales';

type ActiveTabKey = 'sms' | 'voice';

export const useDebtorNotificationsTab = () => {
  const { debtor } = useInjectDebtorDialog();
  const { t } = useLocalI18n('debtor.notifications');
  const isLoading = ref<boolean>(false);
  const tabs = computed<Array<ITab<ActiveTabKey>>>(() => ((([
    {
      key: 'sms',
    },
    {
      key: 'voice',
    },
  ] as Array<ITab<ActiveTabKey> | boolean>))
    .filter(Boolean) as Array<ITab<ActiveTabKey>>)
    .map((tab) => ({
      ...tab,
      label: t(`${tab.key}.tab`),
      id: `debtor_notification_tab_${tab.key}_tab`,
    })));

  const [activeTab] = useDialogRouteParam<ActiveTabKey>(
    'notifications-tab',
    'sms',
    ref(true),
  );

  const apiEndpoints = useDocumentsApi();

  const {
    showDialog,
  } = useDialog();

  const { fetchLocales, locales } = useCustomLocales();

  onMounted(async () => {
    await fetchLocales();
  });

  const voiceStatuses = computed(() => locales.value.ru['debtor.voice_document.status']);

  const {
    records,
    columns,
    actions,
    limit,
    page,
    total,
    fetchData: refetch,
  } = useActiveTable<any & {id: 'id'}, any, 'id'>({
    keyField: 'id',
    async fetch({ params, signal }) {
      if (!debtor.value) {
        return {
          count: 0,
          results: [],
        };
      }
      isLoading.value = true;

      if (records?.value?.length) {
        records.value = [];
      }
      const { response } = await ((({
        sms: apiEndpoints.fetchSms,
        voice: apiEndpoints.fetchVoice,
      } as {
        [key in ActiveTabKey]: any
        // eslint-disable-next-line no-underscore-dangle
      })[params.filters!.__tab as ActiveTabKey])({ ...params, signal }));
      isLoading.value = false;
      return response;
    },
    actions: computed(() => (([
      activeTab.value === 'voice' && {
        key: 'listen',
        icon: 'megaphone',
        types: [ActionType.record],
        label: 'Прослушать',
        handler: async ({ selectedItems: [id] }) => {
          const item = records.value.find((item) => item.id === id);
          if (!item) {
            return;
          }
          const fileResult = await getAuthProtectedFileUrl(item.file);
          if (fileResult) {
            const { name = t('actions.listen') } = item;
            await showDialog({
              component: IDialogComponent.listenFile,
              addInRoute: false,
              payload: {
                name,
                file: fileResult,
              },
              ionOptions: {
                showBackdrop: true,
                breakpoints: [0, 0.2],
                initialBreakpoint: 0.25,
              },
            });
          }
        },
      },
      activeTab.value !== 'sms' && {
        key: 'download',
        icon: 'download',
        types: [ActionType.record],
        label: 'Скачать',
        handler: async ({ selectedItems: [id] }) => {
          const item = records.value.find((item) => item.id === id);
          if (!item) {
            return;
          }
          if (item.file) {
            openAuthProtectedFile(item.file, true);
          }
        },
      },
    ] as Array<ActiveTableAction<any, any> | boolean>)
      .filter(Boolean) as Array<ActiveTableAction<any, any>>)),
    defaultSort: computed(() => ([
      {
        key: 'created_at',
        direction: OrderDirection.desc,
      },
      {
        key: 'id',
        direction: OrderDirection.desc,
      },
    ])),
    defaultLimit: ref(15),
    filters: computed(() => (([
      {
        key: '__tab',
        field: '__tab',
        type: ActiveFormFieldType.input,
        defaultValue: activeTab.value,
      },
      {
        key: 'debtor_id',
        field: 'debtor_id',
        type: ActiveFormFieldType.input,
        defaultValue: debtor.value?.debtor.pk,
      },
    ] as Array<ActiveFormField<any> | boolean>)
      .filter(Boolean) as Array<ActiveFormField<any>>)),
    columns: computed(() => (([
      { key: 'id', width: 1 },
      ['sms'].includes(activeTab.value) && { key: 'operator.name' },
      ['sms'].includes(activeTab.value) && {
        key: 'created_at',
        format: ActiveTableColumnFormat.date,
      },
      {
        key: 'phone_number',
        format: ActiveTableColumnFormat.phone,
        width: 1.25,
      },
      ['sms'].includes(activeTab.value) && { key: 'message', width: 3.5 },
      {
        key: 'send_at',
        label: ['sms'].includes(activeTab.value) ? 'Отправлено' : 'Дата и время',
        format: ActiveTableColumnFormat.dateTime,
      },
      { key: 'status', label: ['sms'].includes(activeTab.value) ? 'Статус' : 'Результат' },
      ['voice'].includes(activeTab.value) && { key: 'status_text' },
      ['voice'].includes(activeTab.value) && {
        key: 'payment_date',
        format: ActiveTableColumnFormat.dateTime,
      },
      ['voice'].includes(activeTab.value) && {
        key: 'payment_amount',
        format: ActiveTableColumnFormat.money,
      },
      ['sms'].includes(activeTab.value) && {
        key: 'service_cost',
        format: ActiveTableColumnFormat.money,
      },
    ] as Array<ActiveTableColumn<any> | boolean>)
      .filter(Boolean) as Array<ActiveTableColumn<any>>)
      .map((column) => ({
        ...column,
        field: column.key,
        label: column?.label ?? t(`column.${String(column.key).split('.').pop()}`),
      }))),
  });

  const {
    getDictMap,
  } = useDicts();

  const substatusesMap = computed(() => (
    getDictMap(DictType.debtorSubstatuses).value
  ));

  watch(debtor, (d) => {
    if (d) {
      refetch();
    }
  });

  return {
    t,
    isLoading,
    activeTab,
    tabs,
    records,
    columns,
    actions,
    limit,
    page,
    total,
    substatusesMap,
    voiceStatuses,
  };
};
