import { useStore } from 'vuex';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import {
  ApiCommand,
  ApiResponse,
  ListingRequestSource,
  ListingResponse,
} from '@/store/modules/api';
import { Company } from '@/hooks/useCompanies';
import { Court } from '@/hooks/useCourts';
import { Debtor } from '@/hooks/useDebtors';
import { DataFileFilters } from '@/types/datafile';
import { useApi } from '@/hooks/useApi';
import { CompanyModuleType } from '@/pages/debtors/_module/useDebtorsActions';
import {
  ConstructorReportTemplateColumns,
  ConstructorReportTemplateHeaders,
  ConstructorReportTemplateHeaderStyle,
} from '@/hooks/useConstructorReportTemplates';
import { commonLegacyApiRequest } from '@urrobot/core/service/commonService';
import { formatListingRequest } from '@/components/activeTable/useActiveTable';
import { unwrapListingApiResponse } from '@/service/api';

export enum ProductionType {
  pretrial = 'pretrial',
  judicial = 'judicial',
  executive = 'executive',
}

export type TemplateType = {
  id: number;
  name: string;
  description: string;
  module?: CompanyModuleType;
  custom_print: boolean;
  production_type: ProductionType;
  company_owner?: number|null;
}

export type FetchTemplateTypesModel = ListingRequestSource<TemplateType> & { company_id?: number }
// & { filters?: { production_type: ProductionType }};
export type FetchTemplateTypesResponse = ListingResponse<TemplateType>;

export type CreateConstructorTemplateTypesModel = Omit<TemplateType, 'id'>;
export type CreateConstructorTemplateTypesResponse = TemplateType;

export type Template = {
  id: number;
  template: Record<string, any> & { template_type: TemplateType & { id: number } };
  production_type: ProductionType;
  company: Company['id'];
  court: Court['id'];
  regional_court: Court['id'];
  content: string;
  name?: string;
}

export type FetchTemplatesModel = ListingRequestSource<Template>;
export type FetchTemplatesResponse = ListingResponse<Template>;

export type ConstructorTemplate = {
  id: number;
  template_type: any;
  production_type: ProductionType;
  description: string;
  name: string;
  company_owner: Company['id'];
  content: string;
  is_landscape_orientation: boolean;
  is_editable: boolean;
  updated_at: Date;
}

export type FetchConstructorTemplatesModel = ListingRequestSource<ConstructorTemplate>;
export type FetchConstructorTemplatesResponse = ListingResponse<ConstructorTemplate>;

export type RemoveConstructorTemplateModel = ConstructorTemplate['id'];
export type RemoveConstructorTemplateResponse = null;

export type UpdateConstructorTemplateModel = {
  id: ConstructorTemplate['id'];
  model: Omit<Partial<Template>, 'template'> & { template: number };
};
export type UpdateConstructorTemplateResponse = ConstructorTemplate;

export type CreateConstructorTemplateModel = Omit<Partial<Template>, 'template'> & { template: number };
export type CreateConstructorTemplateResponse = ConstructorTemplate;

export type RemoveConstructorConstructorTemplateModel = Template['id'];
export type RemoveConstructorConstructorTemplateResponse = null;

export type FetchConstructorConstructorTemplateModel = Template['id'];
export type FetchConstructorConstructorTemplateResponse = Template;

export type UpdateConstructorConstructorTemplateModel = {
  id: ConstructorTemplate['id'];
  model: Partial<ConstructorTemplate>;
};
export type UpdateConstructorConstructorTemplateResponse = ConstructorTemplate;

export type CreateConstructorConstructorTemplateModel = Partial<ConstructorTemplate>;
export type CreateConstructorConstructorTemplateResponse = ConstructorTemplate;

export enum ReportSaveType {
  excel = 'excel',
  csv = 'csv',
  pp3 = 'pp3',
}

// TODO объединить с фильтрами в таблице
export enum HasTenantFilterValue {
  singleholders = 'singleholders',
  shareholders = 'shareholders',
  cohabitees = 'cohabitees',
}

export type ConstructorRenderModel = {
  company_id: Company['id'];
  module: CompanyModuleType;
  date_from?: string;
  date_to?: string;
  document_date_from?: string;
  document_date_to?: string;
  voice_document_date_from?: string;
  voice_document_date_to?: string;
  status_updated_at_before?: string;
  status_updated_at_after?: string;
  production_type: ProductionType;
  columns: Array<[string, string]>;
  // columns: Record<string, string>;
  save_type: ReportSaveType;
  debtor_ids?: Array<Debtor['pk']>;
  filters: Record<any, any> & {
    sms_date_from?: string;
    sms_date_to?: string;
    voice_date_from?: string;
    voice_date_to?: string;
    enrichment_from?: string;
    enrichment_to?: string;
    email_date_from?: string;
    email_date_to?: string;
    has_court_case_id?: true;
    is_organization?: boolean;
    has_tenant_and?: HasTenantFilterValue;
  };
  styles: {
    group_headers?: ConstructorReportTemplateHeaderGroup[];
    headers?: ConstructorReportTemplateHeaders;
    columns?: ConstructorReportTemplateColumns;
  };
  moratorium_enabled: boolean;
  penalty_key_rate_mode: string;
  services: any[];
}

export type ConstructorRenderOldModel = {
  company_id: Company['id'];
  production_type: ProductionType;
  template_type_id: number;
  filters?: Record<any, any>;
}
export type ConstructorRenderResponse = {
  document: {
    company: number;
    file: string;
    id: number;
    name: string;
    template: number;
  };
}

export type ConstructorRenderWsResponse = {
  uuid: string;
}

export type ConstructorRenderDebtorsDataModel = {
  company_id: Company['id'];
  production_type?: ProductionType;
  filters?: DataFileFilters & Record<any, any>;
}
export type ConstructorRenderDebtorsDataResponse = {
  link: string;
}

export type ConstructorVariableGroup = {
  id: number;
  name: string;
  // eslint-disable-next-line no-use-before-define
  vars: Array<ConstructorVariable>;
}

export type ConstructorReportTemplateHeaderGroup = {
  range: [number, number];
  value: string;
  subgroups?: ConstructorReportTemplateHeaderGroup[];
} & ConstructorReportTemplateHeaderStyle;

export type CompanyTemplateType = { id: number; company: number; template_type: number }

export const isHeaderGroup = (
  el: any,
): el is ConstructorReportTemplateHeaderGroup => !!(el as ConstructorReportTemplateHeaderGroup).range;

export type HeaderGroupWithChildren = [[number, number], string]|[[number, number], string, HeaderGroupWithChildren[]];

export type ConstructorVariable = {
  id: number;
  production_type: ProductionType;
  name: string;
  description: string;
  var: string;
  is_func: boolean;
  group: ConstructorVariableGroup['id'];
}

export type FetchConstructorVariablesModel = ListingRequestSource<
  ConstructorVariableGroup & { production_type: ProductionType }
  >;

export type FetchConstructorVariablesResponse = ListingResponse<ConstructorVariableGroup>;

export const useConstructor = () => {
  const store = useStore();
  const api = useApi();

  const fetchTemplateTypes = async (
    model: FetchTemplateTypesModel,
  ): Promise<ApiResponse<FetchTemplateTypesResponse>> => {
    const { status, response } = await store.dispatch('construct/fetchTemplateTypes', model);

    return { status, response };
  };

  const createTemplateType = async (
    model: CreateConstructorTemplateTypesModel,
  ): Promise<ApiResponse<CreateConstructorTemplateTypesResponse>> => {
    const { status, response } = await store.dispatch('construct/createTemplateType', model);

    return { status, response };
  };

  // шаблоны компании
  const fetchTemplates = async (
    model: FetchTemplatesModel,
  ): Promise<ApiResponse<FetchTemplatesResponse>> => {
    const { status, response } = await store.dispatch('construct/fetchTemplates', model);

    return { status, response };
  };

  const removeTemplate = async (
    id: RemoveConstructorTemplateModel,
  ): Promise<ApiResponse<FetchConstructorTemplatesResponse>> => {
    const { status, response } = await store.dispatch('construct/removeTemplate', id);

    return { status, response };
  };

  const updateTemplate = async (
    template: UpdateConstructorTemplateModel,
  ): Promise<ApiResponse<UpdateConstructorTemplateResponse>> => {
    const { status, response } = await store.dispatch('construct/updateTemplate', template);

    return { status, response };
  };

  const createTemplate = async (
    template: CreateConstructorTemplateModel,
  ): Promise<ApiResponse<CreateConstructorTemplateResponse>> => {
    const { status, response } = await store.dispatch('construct/createTemplate', template);

    return { status, response };
  };

  const fetchConstructorTemplates = async (
    model: FetchConstructorTemplatesModel,
  ): Promise<ApiResponse<FetchConstructorTemplatesResponse>> => {
    const { status, response } = await store.dispatch('construct/fetchConstructorTemplates', model);

    return { status, response };
  };

  const removeConstructorTemplate = async (
    id: RemoveConstructorConstructorTemplateModel,
  ): Promise<ApiResponse<FetchConstructorTemplatesResponse>> => {
    const { status, response } = await store.dispatch('construct/removeConstructorTemplate', id);

    return { status, response };
  };

  const fetchConstructorTemplate = async (
    id: FetchConstructorConstructorTemplateModel,
  ): Promise<ApiResponse<FetchConstructorConstructorTemplateResponse>> => {
    const { status, response } = await store.dispatch('construct/fetchConstructorTemplate', id);

    return { status, response };
  };

  const updateConstructorTemplate = async (
    model: UpdateConstructorConstructorTemplateModel,
  ): Promise<ApiResponse<UpdateConstructorConstructorTemplateResponse>> => {
    const { status, response } = await store.dispatch('construct/updateConstructorTemplate', model);

    return { status, response };
  };

  const createConstructorTemplate = async (
    model: CreateConstructorConstructorTemplateModel,
  ): Promise<ApiResponse<CreateConstructorConstructorTemplateResponse>> => {
    const { status, response } = await store.dispatch('construct/createConstructorTemplate', model);

    return { status, response };
  };

  const fetchConstructorVariables = async (
    model: FetchConstructorVariablesModel,
  ): Promise<ApiResponse<FetchConstructorVariablesResponse>> => {
    const { status, response } = await store.dispatch('construct/fetchConstructorVariables', model);

    return { status, response };
  };

  const render = async (
    model: ConstructorRenderOldModel,
  ) : Promise<ApiResponse<ConstructorRenderWsResponse>> => {
    const { status, response } = await store.dispatch('construct/render', model);

    return { status, response };
  };

  const renderWs = async (
    model: ConstructorRenderModel,
  ) : Promise<ApiResponse<ConstructorRenderWsResponse>> => api({
    command: ApiCommand.constructorRenderWs,
    data: model,
    params: {
      company_id: model.company_id,
    },
  });

  const renderDebtorsData = async (
    model: ConstructorRenderDebtorsDataModel,
  ) : Promise<ApiResponse<ConstructorRenderWsResponse>> => {
    const { status, response } = await store.dispatch('construct/renderDebtorsData', model);

    return { status, response };
  };

  const renderDebtorsDataPostal = async (
    model: ConstructorRenderDebtorsDataModel,
  ) : Promise<ApiResponse<ConstructorRenderWsResponse>> => {
    const { status, response } = await store.dispatch('construct/renderDebtorsDataPostal', model);

    return { status, response };
  };

  const fetchCompanyTemplateTypes = (
    company: number,
  ) => commonLegacyApiRequest<ListingResponse<CompanyTemplateType>>({
    command: ApiCommand.companyTemplateTypeFetchList,
    params: formatListingRequest({
      limit: 1000,
      page: 1,
      filters: { company },
    }),
  }).then(unwrapListingApiResponse);

  const createCompanyTemplateType = (
    model: Omit<CompanyTemplateType, 'id'>,
  ) => commonLegacyApiRequest<CompanyTemplateType>({
    command: ApiCommand.companyTemplateTypeCreate,
    data: model,
  });

  const deleteCompanyTemplateType = (
    id: number,
  ) => commonLegacyApiRequest({
    command: ApiCommand.companyTemplateTypeDelete,
    params: { id },
  });

  const removeConstructorTemplateType = (id: number) => commonLegacyApiRequest<undefined>({
    command: ApiCommand.removeConstructorTemplateType,
    params: { id },
  });

  const { t } = useI18n();
  const productionTypes = computed(() => ([
    ProductionType.pretrial,
    ProductionType.judicial,
    ProductionType.executive,
  ].map((value) => ({
    value,
    label: t(`productionType.${value}`),
  }))));

  return {
    createTemplateType,
    fetchTemplateTypes,

    fetchTemplates,
    removeTemplate,
    updateTemplate,
    createTemplate,

    removeConstructorTemplateType,
    fetchConstructorTemplates,
    removeConstructorTemplate,
    fetchConstructorTemplate,
    updateConstructorTemplate,
    createConstructorTemplate,

    fetchConstructorVariables,

    productionTypes,

    fetchCompanyTemplateTypes,
    createCompanyTemplateType,
    deleteCompanyTemplateType,

    render,
    renderDebtorsData,
    renderDebtorsDataPostal,
    renderWs,
  };
};
