import { useTabs } from '@/components/dialog/dialogs/debtor/tabs/courts/useTabs';
import {
  computed, onBeforeUnmount, ref, watch,
} from 'vue';
import {
  useFetchCourtsCases,
} from '@/components/dialog/dialogs/debtor/tabs/courts/useFetchCourtsCases';
import { useFilters } from '@/components/dialog/dialogs/debtor/tabs/courts/useFilters';
import { useInjectDebtorDialog } from '@/components/dialog/dialogs/debtor/useInjectDebtorDialog';
import { CourtCase } from '@/components/dialog/dialogs/debtor/tabs/courts/types';
import { FetchFn } from '@/components/activeTable/useActiveTable';
import { useLocalI18n } from '@/hooks/useLocalI18n';
import { SignalType, useSignal } from '@/hooks/useSignal';
import { useToast } from '@/hooks/useToast';
import { useCourts } from '@/hooks/useCourts';
import { IDialogComponent, useDialog } from '@/hooks/useDialog';
import { getRandomString } from '@/utils/string';
import { useUnsubs } from '@/hooks/useUnsubs';
import {
  useContractualCourts,
} from '@/components/dialog/dialogs/debtor/tabs/courts/useContractualCourts';

type ArchiveGroup = {
  debt_period: string; // title
  fetch: FetchFn<CourtCase>;
}

export function useCourtsTab() {

  const { t } = useLocalI18n('debtor.courts');
  const { subscribeToSignal } = useSignal();
  const { unsub } = useUnsubs();
  const { debtor } = useInjectDebtorDialog();
  const { showPureInfoToast, showPureDangerToast } = useToast();
  const { updateCourtCasesByDebtor } = useCourts();
  const { showDialog } = useDialog();
  const { dispatchSignal } = useSignal();
  const { tabs, activeTab, isContractual } = useTabs();
  const signalId = getRandomString();
  const isLoading = ref<boolean>(false);
  const archiveOpened = ref<boolean>(false);
  const fetchArchived = useFetchCourtsCases(isLoading, true);
  const { params } = useFilters(activeTab);
  const groups = ref<ArchiveGroup[]>([]);
  const { contractualId } = useContractualCourts(debtor, activeTab);

  let unsubCreateTypeDialog: (() => void) | null = null;
  onBeforeUnmount(() => {
    unsubCreateTypeDialog?.();
  });

  subscribeToSignal(SignalType.dialogClosed, (payload: {id: string}) => {
    if (signalId === payload.id) unsubCreateTypeDialog?.();
    unsubCreateTypeDialog = null;
  });

  watch([debtor, activeTab], async () => {
    const { results } = await fetchArchived({ params: params(true) });
    const map: Record<string, CourtCase[]> = {};
    results.forEach((item) => {
      const key = item.debt_period;
      if (!map[key]) {
        map[key] = [];
      }
      map[key].push(item);
    });
    groups.value = Object.entries(map)
      .sort(([period1], [period2]) => {
        try {
          const start1 = parseDate(period1.split('-')[0].trim());
          const start2 = parseDate(period2.split('-')[1].trim());
          return start2.getTime() - start1.getTime();
        } catch (e) {
          console.error('Failed to sort', e);
          return -1;
        }
      })
      .map(([key, items]) => ({
        debt_period: key,
        async fetch() {
          return {
            results: items,
            count: items.length,
          };
        },
      }));
  }, { immediate: true });

  function parseDate(dateString: string) {
    const parts = dateString.split('.');
    if (parts.length === 3) {
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1; // Month is 0-based
      const year = parseInt(parts[2], 10);
      return new Date(year, month, day);
    }
    return new Date();
  }

  const showCourtDetails = async () => {
    unsubCreateTypeDialog?.();
    const debtorProfile = debtor.value?.debtor_main_profile;
    const tenantProfiles = debtor.value.debtor_tenant_profiles;
    const courtField = `${activeTab.value}_court_place`;

    const tenantWithRegistrationId: string | null = tenantProfiles.map((tenant) => (tenant?.registration_address_obj?.[courtField])).filter(Boolean)[0] ?? null;
    const tenantRegAddress = tenantProfiles.find((tenant) => tenant?.registration_address_obj?.[courtField] === tenantWithRegistrationId)?.registration_address_st ?? null;
    const addressId = debtorProfile?.addr_st?.[courtField] ?? null;
    const registrationId = debtorProfile?.registration_address_obj?.[courtField] ?? tenantWithRegistrationId ?? null;

    unsubCreateTypeDialog = await showDialog({
      component: IDialogComponent.courtDetails,
      addInRoute: false,
      payload: {
        debtorId: debtor.value.debtor_main_profile.id,
        addressId,
        registrationId,
        tenantRegAddress: tenantProfiles.length > 1 ? tenantRegAddress : '',
        contractualId: contractualId.value,
        type: activeTab.value,
        signal: signalId,
      },
    });
  };

  const updateCourtCases = async () => {
    const { status, response, statusCode } = await updateCourtCasesByDebtor(debtor.value.debtor.pk);
    if (status) {
      await dispatchSignal(SignalType.debtorCourtCasesUpdated);

    } else if (statusCode === 404) {
      await showPureInfoToast({ params: { label: 'Данные по ФИО не найдены' } });

    } else {
      console.error('Error updating court cases', response);
      await showPureDangerToast({ params: { label: 'Ошибка на сервере' } });
    }
  };

  const unsubSignal = subscribeToSignal(SignalType.debtorCourtUpdated, ({ patch }) => {
    debtor.value = {
      ...debtor.value,
      debtor_main_profile: {
        ...debtor.value.debtor_main_profile,
        ...patch,
      },
    };
  });

  onBeforeUnmount(() => {
    unsubSignal && unsub(unsubSignal);
  });

  return {
    t,
    tabs,
    activeTab,
    groups,
    isLoading,
    archiveOpened,
    showCourtDetails,
    updateCourtCases,
    contractualId,
    isContractual,
  };
}
