import { computed } from 'vue';
import {
  ActiveTableColumn,
  ActiveTableColumnFormat,
} from '@/components/activeTable/useActiveTable';
import { isOrganization } from '@/components/dialog/dialogs/debtor/isOrganization';
import { useInjectDebtorDialog } from '@/components/dialog/dialogs/debtor/useInjectDebtorDialog';
import { useLocalI18n } from '@/hooks/useLocalI18n';

export function useColumns() {
  const { debtor } = useInjectDebtorDialog();
  const { t } = useLocalI18n('debtor.courts');

  return computed(() => (([
    { key: 'index', width: '180px' },
    { key: 'complaint_type', width: '200px' },
    { key: 'document_number', width: '140px' },
    { key: 'case_number', width: '140px' },
    { key: 'writ_number', width: '180px' },
    { key: 'case_id', width: '160px' },
    { key: 'category_name', width: '240px' },
    { key: 'filing_date', format: ActiveTableColumnFormat.date, width: '180px' },
    { key: 'case_consideration_date', format: ActiveTableColumnFormat.date, width: '180px' },
    { key: 'effective_decision_date', format: ActiveTableColumnFormat.date, width: '140px' },
    {
      key: 'full_names',
      field: 'tracker.full_names',
      getValue: (record) => record.tracker?.full_names?.join('\n') || undefined,
      width: '240px',
      label: isOrganization(debtor.value) ? 'Наименование' : 'ФИО должника',
    },
    { key: 'judge_full_name', width: '140px' },
    {
      key: 'sittings',
      width: '440px',
      field: 'sittings',
      getValue: (record) => (record.sittings.length ? record.sittings[record.sittings.length - 1] : null),
    },
    { key: 'payment_status', field: 'tracker.current_status' },
    { key: 'status_history', field: 'tracker.history', width: '440px' },
    { key: 'comment', field: 'comment', width: '240px' },
    { key: 'log', field: 'log', width: '140px' },
    { key: 'debt_period', width: '180px' },
    { key: 'doc_number', label: 'Номер ПП', width: '140px' },
    {
      key: 'payment_document_date',
      label: 'Дата оплаты ПП',
      field: 'tracker.payment_document_date',
      format: ActiveTableColumnFormat.date,
      width: '170px',
    },
    { key: 'court_place', label: 'Наименование суда', width: '180px' },
    { key: 'total_debt', label: 'Сумма общей задолженности', width: '150px' },
    { key: 'debt', label: 'Сумма задолженности', width: '150px' },
    { key: 'penalty', label: 'Пеня' },
    { key: 'fee', label: 'Гос. пошлина' },
    { key: 'paid_debt', label: 'Сумма оплаченной задолженности' },
    { key: 'paid_penalty', label: 'Сумма оплаченной пени' },
    { key: 'paid_fee', label: 'Сумма оплаченной пошлины' },

  ] as Array<ActiveTableColumn<any> | boolean>)

    .filter(Boolean) as Array<ActiveTableColumn<any>>)

    .map((column) => ({
      ...column,
      field: column.field || column.key,
      label: column.label ?? t(`column.${String(column.key).split('.').pop()}`),
    })));
}
