import { DebtorStatusPayload } from '@/hooks/useDialog';
import {
  computed, ref, SetupContext, watch,
} from 'vue';
import { useLocalI18n } from '@/hooks/useLocalI18n';
import { DictType, useDicts } from '@/hooks/useDicts';
import {
  Debtor,
  DebtorStatus,
  DebtorStatusNew,
  DebtorSubstatus,
  useDebtors,
} from '@/hooks/useDebtors';
import { awaitFrame } from '@/utils/window';
import { SignalType, useSignal } from '@/hooks/useSignal';

export const useDebtorStatusDialog = (props: DebtorStatusPayload, emit: SetupContext['emit']|any) => {
  const { t } = useLocalI18n('debtorStatus');
  const {
    getDict,
  } = useDicts();

  const statuses = computed(
    () => getDict(DictType.debtorStatuses).value.filter(
      (s: any) => s.production_type === props.productionType || !s.production_type,
    ),
  );

  const substatuses = computed(
    () => getDict<{ production_type: DebtorStatusPayload['productionType'] }>(DictType.debtorSubstatuses).value
      .filter(
        ({ production_type }) => production_type === null
          || production_type === props.productionType,
      ),
  );

  const {
    fetchDebtorStatusEntries,
    updateDebtorStatusEntries,
    updateDebstorsStatusesEntries,
  } = useDebtors();

  const entries = ref<Array<DebtorStatus|DebtorStatusNew>>([]);
  const activeEntryKey = ref<DebtorStatus['status']>();
  const debtorId = computed<Debtor['pk'] | null>(() => (
    props.debtorIds?.length === 1 ? props.debtorIds[0] : null));
  const fetchEntries = async () => {
    activeEntryKey.value = undefined;
    const {
      status,
      response,
    } = await fetchDebtorStatusEntries({
      debtorId: debtorId.value!,
      productionType: props.productionType,
    });
    if (status) {
      entries.value = response.entries;
      activeEntryKey.value = response.activeEntryKey;
    }
  };

  const subEntries = computed<Array<DebtorSubstatus>>(
    () => ([...entries.value.find(
      ({ status }) => status === activeEntryKey.value,
    )?.substatus || []]).sort((a, b) => (
      new Date(a.updated_at!) > new Date(b.updated_at!) ? 1 : -1
    )),
  );

  const addSubEntry = () => {
    const statusEntry = entries.value.find(
      ({ status }) => status === activeEntryKey.value,
    );
    if (!statusEntry) {
      entries.value.push({
        status: activeEntryKey.value as string,
        substatus: [] as DebtorSubstatus[],
      });
    }
    entries.value.find(
      ({ status }) => status === activeEntryKey.value,
    )?.substatus.push({
      updated_at: null,
      substatus: null,
      data: '',
    } as unknown as DebtorSubstatus);
  };

  watch(debtorId, async (newId) => {
    entries.value = [];
    if (newId) {
      await awaitFrame();
      await fetchEntries();
    }
  }, {
    immediate: true,
  });

  const {
    dispatchSignal, awaitSignalResponse,
  } = useSignal();

  const submit = async () => {
    if (!props.debtorIds?.length) {
      const localFilters = await awaitSignalResponse<Record<any, any>
        >(
          SignalType.getDebtorFilters,
          SignalType.debtorFilters,
        );

      await updateDebstorsStatusesEntries({
        productionType: props.productionType,
        entries: entries.value,
        activeEntryKey: activeEntryKey.value,
        filters: localFilters,
      });
    } else {
      await Promise.all(
        props.debtorIds?.map(async (id) => updateDebtorStatusEntries({
          id,
          productionType: props.productionType,
          entries: entries.value,
          activeEntryKey: activeEntryKey.value,
        })) || [],
      );
    }
    await dispatchSignal(SignalType.debtorsUpdated);
    emit('close');
  };

  return {
    statuses,
    substatuses,
    t,

    entries,
    activeEntryKey,
    subEntries,

    addSubEntry,
    submit,
  };
};
