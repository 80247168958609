// @ts-nocheck third-party-js
/* eslint-disable */

import {
  CAPICOM_CERTIFICATE_FIND_EXTENDED_PROPERTY,
  CAPICOM_CERTIFICATE_FIND_SHA1_HASH, CAPICOM_CERTIFICATE_FIND_TIME_VALID, CAPICOM_PROPID_KEY_PROV_INFO,
} from '@core/plugins/cryptoPro/consts';

import {cryptoProCheck, CryptoProCheckResult} from "@core/plugins/cryptoPro/check";
import { Certificate, getUserCertificates } from '@core/plugins/cryptoPro/getUserCertificates';
import { getRandomString } from '@/utils/string';
import { getTaskByChunksExecutor } from '@/utils/async';

var CADESCOM_CADES_BES = 1;
var CAPICOM_CURRENT_USER_STORE = 2;
var CAPICOM_MY_STORE = "My";
var CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED = 2;
var CAPICOM_CERTIFICATE_FIND_SUBJECT_NAME = 1;
var CADESCOM_BASE64_TO_BINARY = 1;

function doCheck() {
  // Проверяем, работает ли File API
  if (window.FileReader) {
    // Браузер поддерживает File API.
  } else {
    alert("The File APIs are not fully supported in this browser.");
  }
  var fileReader = new FileReader();
  if (typeof (fileReader.readAsDataURL) !== "function") {
    alert("Method readAsDataURL() is not supported in FileReader.");
    return;
  }
}

function getFileName(filename) {
  var fileinput = document.getElementById(filename);
  if (!fileinput)
    return "";
  var filename = fileinput.value;
  if (filename.length == 0)
    return "";
  var dot = filename.lastIndexOf(".");
  if (dot == -1)
    return "";
  var name = filename.substr(0, dot);
  return name;
}

export const getReadyToSignUserCertificateByThumbPrint = async (thumbprint: string) => {
  try {
    const certs = await getUserCertificates();
    console.log('certs', certs, thumbprint);
    const cert = certs.find((cert) => cert.thumbprint === thumbprint);
    if (!cert) {
      return;
    }
    // Запоминаем пароль от контейнера закрытого ключа, чтобы не вводить повторно
    const oCertificatePrivateKey = await cert._obj.PrivateKey;
    oCertificatePrivateKey.propset_CachePin(true);
    return cert._obj;
  } catch (e) {
    console.error(e);
    return;
  }
}

export async function signFileWithCertObj(
  oCertificate: Certificate['_obj'],
  oFile: File,
) {
  return new Promise<File|Error>(async (resolve) => {

    const plugin = await window.cadesplugin.catch(e => e);
    console.log('plugin', plugin);
    if (plugin === 'Плагин недоступен') {
      console.error(new Error('Плагин недоступен'));
      resolve(new Error('Плагин недоступен'))
      return;
    }

    const checkResult = await cryptoProCheck();
    if (!checkResult.enabled) {
      return;
    }
    function run() {
      cadesplugin.async_spawn(function* (args) {
        var oFReader = new FileReader();

        if (typeof (oFReader.readAsDataURL) != "function") {
          resolve(new Error('Method readAsDataURL() is not supported in FileReader'))
          return;
        }

        oFReader.readAsDataURL(oFile);

        oFReader.onload = function (oFREvent) {
          cadesplugin.async_spawn(function* (args) {
            var header = ";base64,";
            var sFileData = oFREvent.target.result;
            var sBase64Data = sFileData.substr(sFileData.indexOf(header) + header.length);

            var oSigner = yield cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner");
            yield oSigner.propset_Certificate(oCertificate);
            yield oSigner.propset_CheckCertificate(true);

            var oSignedData = yield cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");
            yield oSignedData.propset_ContentEncoding(CADESCOM_BASE64_TO_BINARY);
            yield oSignedData.propset_Content(sBase64Data);

            try {
              var sSignedMessage = yield oSignedData.SignCades(oSigner, CADESCOM_CADES_BES, true);
            } catch (err) {
              console.error(new Error(`Ошибка создания подписи ${cadesplugin.getLastError(err)}`));
              resolve(new Error(`Ошибка создания подписи ${cadesplugin.getLastError(err)}`));
              return;
            }

            console.log('after sign');
            try {

              // var oSignedData2 = yield cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");

              // console.log('after sign 1 11', oSignedData2);
              // yield oSignedData2.propset_ContentEncoding(CADESCOM_BASE64_TO_BINARY);

              // console.log('after sign 2');
              // yield oSignedData2.propset_Content(sBase64Data);

              // console.log('after sign 3');
              // yield oSignedData2.VerifyCades(sSignedMessage, CADESCOM_CADES_BES, true);

              // console.log('after sign 4');
              // const fileName = getFileName(oFile.name);
              // console.log('fileName', fileName, oFile.name);
              const releaseResult = cadesplugin.ReleasePluginObjects();
              console.log('releaseResult', releaseResult);
              resolve(new File(
                [sSignedMessage],
                `document_sign.sig`,
                { type: 'text/plain' }
              ));
            } catch (err) {
              console.error(err);
              console.error(new Error(`Ошибка проверки подписи ${cadesplugin.getLastError(err)}`));
              resolve(new Error(`Ошибка проверки подписи ${cadesplugin.getLastError(err)}`));
              return;
            }
          });
        };
      });
    }

    run();

  })
}

let releaseObjectBlockingPromise: Promise<void>|undefined = undefined;

let signingCounter = 0;
let signingPromise: Promise<void>|undefined = undefined;
let signingPromiseResolver: (() => void)|undefined = undefined;
let waitingPromises: Promise<void>[] = [];

let sequenceCounter = 0;

let sequencePromise: Promise<void>|undefined = undefined;
let sequencePromises: Promise<void>[] = [];
let objectsIsReleasing = false;
export const runInChunk = async (fn: () => Promise<void>) => {
  // счетчик для наглядности
  const randomId = signingCounter;
  signingCounter++;
  console.log('runInChunk', randomId, 'counter', sequenceCounter);
  if (sequenceCounter >= 10) {
    console.log('runInChunk await sequencePromises', randomId, sequencePromises.length);
    await Promise.all(sequencePromises);
    console.log('runInChunk awaited sequencePromises', randomId, sequencePromises.length);
    console.log('runInChunk awaited sequencePromises', randomId, 'objectsIsReleasing', objectsIsReleasing);

    if (!objectsIsReleasing) {
      // Очищаем объекты плагина, когда все таски во фрагменте запущены
      objectsIsReleasing = true;
      sequencePromise = new Promise((resolve) => {
        cadesplugin.async_spawn(function* (args) {
          yield cadesplugin.ReleasePluginObjects();
          resolve();
        })
      })
      await sequencePromise;
      sequencePromise = undefined;
      console.log('runInChunk awaited ReleasePluginObjects', randomId, sequencePromises.length);
      sequencePromises = [];
      sequenceCounter = 0;
      objectsIsReleasing = false;
    } else {
      console.log('runInChunk', randomId, 'await releaseObjects', sequencePromise);
      if (!sequencePromise) {
        console.error('ahtung')
      }
      await sequencePromise;
    }
    return runInChunk(fn);
  }
  sequenceCounter++;
  const resultPromise = fn();
  sequencePromises.push(resultPromise);
  console.log('runInChunk promise pushed', randomId, 'counter', sequenceCounter);
  return resultPromise;
}

const executeTaskByChunks = getTaskByChunksExecutor(200);

// создаваемые плагином объекты нужно чистить, иначе он виснет
const cleanUpCryptoProPluginObjects = async () => new Promise<void>((resolve) => {
  cadesplugin.async_spawn(function* (args) {
    yield cadesplugin.ReleasePluginObjects();
    resolve();
  })
})

export const executeCryptoProTaskWithObjectsCleanUp = <T>(fn: () => Promise<T>) => executeTaskByChunks(
  fn, cleanUpCryptoProPluginObjects
)

// при запуске этой функции cadesplugin должен быть зарезолвлен
export async function signFileWithCertThumbprint(
  thumbprint: string,
  oFile: File,
) {
  return new Promise<File|Error>(async (resolve) => {

    // const plugin = await window.cadesplugin.catch(e => e);
    // console.log('plugin', plugin);
    // if (plugin === 'Плагин недоступен') {
    //   console.error(new Error('Плагин недоступен'));
    //   resolve(new Error('Плагин недоступен'))
    //   return;
    // }

    // const checkResult = await cryptoProCheck();
    // if (!checkResult.enabled) {
    //   resolve(new Error(`can not check crypto pro`))
    //   return;
    // }
    async function run() {
      try {
        cadesplugin.async_spawn(function* (args) {
          var oFReader = new FileReader();

          if (typeof (oFReader.readAsDataURL) != "function") {
            resolve(new Error('Method readAsDataURL() is not supported in FileReader'))
            return;
          }

          oFReader.readAsDataURL(oFile);

          oFReader.onload = function (oFREvent) {
            cadesplugin.async_spawn(function* (args) {
              try {

                const oStore = yield cadesplugin.CreateObjectAsync('CAdESCOM.Store');
                yield oStore.Open(
                  CAPICOM_CURRENT_USER_STORE,
                  CAPICOM_MY_STORE,
                  CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED,
                );
                const oStoreCerts = yield oStore.Certificates;
                let oCertificates = yield oStoreCerts.Find(
                  CAPICOM_CERTIFICATE_FIND_TIME_VALID,
                );
                oCertificates = yield oCertificates.Find(
                  CAPICOM_CERTIFICATE_FIND_EXTENDED_PROPERTY,
                  CAPICOM_PROPID_KEY_PROV_INFO,
                );
                let nCertificatesCount = yield oCertificates.Count;

                if (!nCertificatesCount) {
                  throw new Error(`Ошибка создания подписи: список сертификатов пуст`);
                }

                let oCertificate
                while (nCertificatesCount && !oCertificate) {
                  const cert = yield oCertificates.Item(nCertificatesCount);
                  const certThumbPrint = yield cert.Thumbprint;
                  if (certThumbPrint === thumbprint) {
                    oCertificate = cert;
                    // Запоминаем пароль от контейнера закрытого ключа, чтобы не вводить повторно
                    const oCertificatePrivateKey = yield cert.PrivateKey;
                    console.log('oCertificatePrivateKey', oCertificatePrivateKey);
                    oCertificatePrivateKey.propset_CachePin(true);
                  }
                  nCertificatesCount--;
                }

                if (!oCertificate) {
                  throw new Error(`Ошибка создания подписи: сертификат ${thumbprint} не найден`);
                }

                console.log('oCertificate', oCertificate);

                var header = ";base64,";
                var sFileData = oFREvent.target.result;
                var sBase64Data = sFileData.substr(sFileData.indexOf(header) + header.length);

                var oSigner = yield cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner");
                yield oSigner.propset_Certificate(oCertificate);
                yield oSigner.propset_CheckCertificate(true);

                var oSignedData = yield cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");
                yield oSignedData.propset_ContentEncoding(CADESCOM_BASE64_TO_BINARY);
                yield oSignedData.propset_Content(sBase64Data);

                try {
                  var sSignedMessage = yield oSignedData.SignCades(oSigner, CADESCOM_CADES_BES, true);
                } catch (err) {
                  console.error(new Error(`Ошибка создания подписи ${cadesplugin.getLastError(err)}`));
                  resolve(new Error(`Ошибка создания подписи ${cadesplugin.getLastError(err)}`));
                  return;
                }

                console.log('after sign');
                try {

                  var oSignedData2 = yield cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");

                  yield oSignedData2.propset_ContentEncoding(CADESCOM_BASE64_TO_BINARY);

                  yield oSignedData2.propset_Content(sBase64Data);

                  yield oSignedData2.VerifyCades(sSignedMessage, CADESCOM_CADES_BES, true);

                  resolve(new File(
                    [sSignedMessage],
                    `document_sign.sig`,
                    { type: 'text/plain' }
                  ));
                } catch (err) {
                  console.error(err);
                  console.error(new Error(`Ошибка проверки подписи ${cadesplugin.getLastError(err)}`));
                  resolve(new Error(`Ошибка проверки подписи ${cadesplugin.getLastError(err)}`));
                  return;
                }

              } catch (e) {
                console.error(`some sign error: ${e}`);
                resolve(e);
              }
            });
          };
        });
      } catch (e) {
        console.error(`Some sign error 1 ${e}`);
        resolve(e);
      }
    }

    run();

  })
}
