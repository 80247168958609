import { Module, Plugin } from 'vuex';
import { StoreState } from '@/store';
import { socketURL } from '@/utils/env';
import { getRandomString } from '@/utils/string';
import { IToastLevel } from '@/hooks/useToast';
import { SocketMessage, SocketMsg } from '@/types/socket';
import * as E from 'fp-ts/Either';
import * as O from 'fp-ts/Option';
import * as TE from 'fp-ts/TaskEither';
import { pipe, flow } from 'fp-ts/function';
import { StoreJwtToken, StoreJwtTokenData } from '@/hooks/useUser';
import { AuthService } from '@urrobot/core/service/authService';

export type SocketSubscriber<T = SocketMsg> = {
  id: string;
  condition(payload: SocketMsg): boolean;
  handler(payload: T): void | Promise<void>;
};

let socket: WebSocket | null = null;
let socketSubscribers: Array<SocketSubscriber>;

export const plugins: Array<Plugin<StoreState>> = [
  (store) => {
    socketSubscribers = [];
    socket = null;

    const connect = () => {
      store.commit('socket/setIsConnected', false);
      socket = null;

      socket = new WebSocket(
        `${socketURL}/ws/`,
      );

      // @ts-ignore
      window.testSocketMessage = (payload: any) => {
        socketSubscribers.filter(({ condition }) => condition(payload)).forEach(({ handler }) => {
          handler(payload);
        });
      };

      socket.addEventListener('message', (e) => {
        const data = JSON.parse(e.data).payload;
        if (data.action === 'subscribe_company' && data.response_status && data.response_status !== 201) {
          console.error(`socket error: can not subscribe to company: ${data.errors}`);
        }
        socketSubscribers.filter(({ condition }) => condition(data)).forEach(({ handler }) => {
          handler(data);
        });
      });

      socket.addEventListener('open', () => {
        store.commit('socket/setIsConnected', true);

        store.watch(
          (state) => state.companies.defaultCompanyId,
          async (newId, oldId) => {
            if (oldId) {
              await store.dispatch('socket/unsubscribeFromCompany', oldId);
            }
            if (newId) {
              await store.dispatch('socket/subscribeToCompany', newId);
            }
          }, {
            immediate: true,
          },
        );
      });

      let timeout: number;
      const retry = async () => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          // store.dispatch('layout/showToast', {
          //   type: 'error',
          //   message: 'socket.retry',
          //   level: IToastLevel.danger,
          // });
          socket = null;
          connect();
        }, 5000);
      };

      socket.addEventListener('close', () => {
        store.commit('socket/setIsConnected', false);
        retry();
      });
      socket.addEventListener('error', () => {
        store.commit('socket/setIsConnected', false);
        retry();
      });
    };

    connect();
  },
];

export type SocketState = {
  isConnected: boolean;
  isConnectedPromiseResolver: (() => void)|null;
  isConnectedPromise: Promise<void>|null;

}

type SocketModule = Module<SocketState, StoreState>;

export const namespaced = true;
export const state: SocketModule['state'] = () => ({
  isConnected: true,
  isConnectedPromiseResolver: null,
  isConnectedPromise: null,
});

export const getters: SocketModule['getters'] = {
  isConnected: (state) => state.isConnected,
};

export const mutations: SocketModule['mutations'] = {
  setIsConnected: (state, isConnected: boolean) => {
    state.isConnected = isConnected;
    if (isConnected) {
      state.isConnectedPromiseResolver?.();
    } else {
      state.isConnectedPromise = new Promise((resolve) => {
        state.isConnectedPromiseResolver = resolve;
      });
    }
  },
};

export const actions: SocketModule['actions'] = {
  async send(context, payload) {
    await context.state.isConnectedPromise;
    const body = {
      ...payload,
    };
    await socket?.send(JSON.stringify(body));
  },
  async unsubscribe(context, id) {
    await context.state.isConnectedPromise;
    socketSubscribers.splice(
      socketSubscribers.findIndex(
        ({ id: sId }) => sId === id,
      ),
      1,
    );
  },
  async subscribe({ dispatch, state }, { condition, handler }) {
    await state.isConnectedPromise;
    const id = getRandomString();
    socketSubscribers.push({
      id,
      condition,
      handler,
    });

    const unsubscribe = () => {
      dispatch('unsubscribe', id);
    };
    unsubscribe.id = id;

    return unsubscribe;
  },
  async unsubscribeFromCompany({ dispatch, state }, id: number) {
    await state.isConnectedPromise;
    await dispatch('send', {
      stream: 'company_events',
      payload: {
        action: 'unsubscribe_company',
        company_id: String(id),
        request_id: getRandomString(),
      },
    });
  },
  async subscribeToCompany({
    commit, dispatch, state, rootGetters,
  }, id: number) {
    await state.isConnectedPromise;

    const tokensData = await (dispatch(
      'user/getJwtTokens', undefined, { root: true },
    ) as Promise<E.Either<O.Option<any>, StoreJwtToken>>);

    if (E.isLeft(tokensData)) {
      return;
    }

    const token = tokensData.right.access.value;
    await dispatch('send', {
      stream: 'company_events',
      payload: {
        action: 'subscribe_company',
        company_id: String(id),
        request_id: getRandomString(),
        user_id: rootGetters['user/data']?.id,
        jwt_token: token,
      },
    });
  },
};
