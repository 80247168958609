import { ActiveTableColumn, ActiveTableColumnFormat } from '@/components/activeTable/useActiveTable';
import { CompanyModuleType } from '@/pages/debtors/_module/useDebtorsActions';
import {
  FinanceTabKey,
  DebtorFinanceData,
  GetFinanceDataCommon,
} from '@/components/dialog/dialogs/debtor/tabs/finance/types';
import { excludeDots } from '@/components/dialog/dialogs/debtor/tabs/finance/useTable/excludeDots';

const isRecalc = (label: string) => (label || '').toLowerCase() === 'перерасчет';
const not = (fn: (v: any) => boolean) => (value: any) => !fn.call(fn, value);

function sortableDate(key: string, label?: string) {
  return {
    key,
    label,
    width: '200px',
    format: ActiveTableColumnFormat.date,
    isSortable: true,
  };
}

function value() {
  return {
    key: 'value',
    format: ActiveTableColumnFormat.money,
  };
}

function recalculationColumns(financeData: DebtorFinanceData) {
  return [
    sortableDate('date'),
    {
      key: 'title',
      width: '200px',
      label: 'Наименование',
    },
    value(),
  ];
}

const defaults = {
  accruals: (financeData: DebtorFinanceData) => ([
    sortableDate('date', 'Месяц начислений'),
    ...(
      financeData.accruals_data.columns.length
        ? financeData.accruals_data.columns.map((label) => ({
          label,
          key: excludeDots(label),
          format: ActiveTableColumnFormat.money,
        }))
        : [{
          key: 'Входящее сальдо',
          label: 'Входящее сальдо',
          format: ActiveTableColumnFormat.money,
        }]),
  ]),
  'paid-ups': (financeData: DebtorFinanceData) => ([
    sortableDate('date'),
    ...financeData.paid_ups_data.columns.filter(not(isRecalc)).map((label) => ({
      key: excludeDots(label),
      label,
      format: ActiveTableColumnFormat.money,
    })) || [],
  ]),
  recalculations: recalculationColumns,
  debts: () => ([
    sortableDate('start_date'),
    {
      key: 'end_date',
      format: ActiveTableColumnFormat.date,
    },
    value(),
  ]),
  penalties: () => ([
    sortableDate('start_date'),
    value(),
  ]),
  fee: () => ([
    sortableDate('start_date'),
    value(),
  ]),
};

const debtorFinanceColumnsMapper = {
  1: defaults,
  4: defaults,
  2: {
    accruals: () => ([
      sortableDate('date', 'Месяц начислений'),
      {
        key: 'transaction',
        width: '200px',
      },
      {
        key: 'cost',
        width: '200px',
        format: ActiveTableColumnFormat.money,
      },
      {
        key: 'payment_type',
        width: '200px',
      },
      {
        key: 'place',
        width: 'minmax(300px, 2fr)',
      },
      {
        key: 'operator_name',
        width: '200px',
      },
    ]),
    'paid-ups': () => ([
      sortableDate('transaction_date'),
      {
        key: 'transaction',
        width: '200px',
      },
      {
        key: 'cost',
        width: '200px',
        format: ActiveTableColumnFormat.money,
      },
      {
        key: 'payment_type',
        width: '200px',
      },
      {
        key: 'place',
        width: 'minmax(300px, 2fr)',
      },
      {
        key: 'operator_name',
        width: '200px',
      },
    ]),
    debts: () => ([
      sortableDate('start_date'),
      {
        key: 'end_date',
        format: ActiveTableColumnFormat.date,
      },
      {
        key: 'value',
        format: ActiveTableColumnFormat.money,
      },
      {
        key: 'place',
        width: 'minmax(80px, 2fr)',
      },
      {
        key: 'operator_name',
      },
    ]),
    penalties: () => ([
      sortableDate('start_date'),
      value(),
    ]),
    fee: () => ([
      sortableDate('start_date'),
      value(),
    ]),
    recalculations: () => ([]),
  },
  3: {
    accruals: (financeData: DebtorFinanceData) => ([
      sortableDate('date', 'Месяц начислений'),
      ...financeData.accruals_data.columns.map((label) => ({
        key: label,
        label,
        format: ActiveTableColumnFormat.money,
      })) || [],
    ]),
    'paid-ups': (financeData: DebtorFinanceData) => ([
      sortableDate('date'),
      ...financeData.paid_ups_data.columns.filter(not(isRecalc)).map((label) => ({
        key: label,
        label,
        format: ActiveTableColumnFormat.money,
      })) || [],
    ]),
    recalculations: recalculationColumns,
    debts: () => ([
      sortableDate('start_date'),
      {
        key: 'end_date',
        format: ActiveTableColumnFormat.date,
      },
      value(),
    ]),
    penalties: () => ([
      sortableDate('start_date'),
      value(),
    ]),
    fee: () => ([
      sortableDate('start_date'),
      value(),
    ]),
  },
} as Record<
    CompanyModuleType, Record<
      FinanceTabKey, (financeData: DebtorFinanceData, serviceTab?: string) => Array<ActiveTableColumn<Record<string, any>>
    >
  >>;

export const getDebtorFinancesTableColumns = ({
  financeData,
  companyModuleType,
  activeTab,
}: GetFinanceDataCommon) => debtorFinanceColumnsMapper[companyModuleType][activeTab](
  financeData,
);
