import { PlateChartOptions } from '@/components/plate/plateIndicator/types';

export const getSimpleRingIndicator = (
  {
    value,
    entireValue,
    formattedValue,
  }: { value: number; entireValue: number; formattedValue: string },
) => ({
  title: {
    text: formattedValue,
    textStyle: {
      color: '#5e6476',
      fontSize: '24px',
      fontWeight: 500,
    },
    subtext: 'Всего',
    subtextStyle: {
      color: '#5e6476',
      fontSize: '12px',
      fontWeight: 400,
    },
    left: 'center',
    top: '35%',
  },
  polar: {
    radius: ['80%', '90%'],
  },
  axisLine: {
    show: false,
  },
  angleAxis: {
    show: false,
    max: entireValue,
    startAngle: 75,
  },
  radiusAxis: {
    show: false,
    type: 'category',
  },
  tooltip: {
    show: false,
  },
  series: [{
    type: 'bar',
    data: [value],
    coordinateSystem: 'polar',
    label: {
      show: false,
      position: 'middle',
      formatter: '{b}: {c}',
    },
  }, {
    type: 'bar',
    data: [entireValue],
    barGap: '-100%',
    barCategoryGap: '50%',
    coordinateSystem: 'polar',
    z: 0,
    emphasis: {
      focus: 'none',
    },
    label: {
      show: false,
      position: 'middle',
      formatter: '{b}: {c}',
    },
    itemStyle: {
      color: '#F2F6FC',
    },
  }],
}) as Omit<PlateChartOptions, 'dataset'>;
