import { CHART_COLORS, DefaultLegendConfig, DefaultTooltipConfig } from '@/components/plate/utils';
import { formatMoney } from '@/utils/string';
import {
  ChartLineVariant, getBarChart,
  LineChartBasicOptions,
} from '@/components/plate/chartConfigs/lineChart/line-chart';
import { envIsMobile } from '@/utils/env';
import { isTablet, isPhablet } from '@/utils/device';

export enum ChartPieVariant {
  Pie = 'pie',
  Ring = 'ring',
  HorizontalBars = 'horizontal-bars',
  VerticalBars = 'vertical-bars',
}

const pieChart1 = {
  tooltip: {
    trigger: 'item',
    textStyle: {
      width: '50px',
    },
  },
  color: CHART_COLORS,
  legend: {
    show: true,
    orient: 'vertical',
    left: '0',
    align: 'left',
    bottom: '0',
    icon: 'roundRect',
    width: '100%',
  },
  toltip: {
    show: true,
    textStyle: {
      width: 50,
    },
  },
  dataset: {
    // dimensions: ,
    source: [
      ['name', 'value', 'formatted'],
      ['Задолженность', 1048, formatMoney(1048)],
      ['В работе', 735, formatMoney(1048)],
      ['Должников', 580, formatMoney(1048)],
      ['В суде', 484, formatMoney(1048)],
    ],
  },
  series: {
    type: 'pie',
    id: 'pie',
    top: 0,
    left: '10%',
    right: '10%',
    bottom: '40%',
    encode: {
      itemName: 'name',
      value: 'value',
      tooltip: 'formatted',
    },
    label: {
      show: false,
    },
    emphasis: {
      itemStyle: {
        shadowBlur: 10,
        shadowOffsetX: 0,
        shadowColor: 'rgba(0, 0, 0, 0.5)',
      },
    },
  },
};

const ringChart1 = {
  title: {
    text: '11 293',
    textStyle: {
      color: '#5e6476',
      fontSize: envIsMobile
        ? (isTablet || isPhablet) ? '16px' : (window.screen.width < 300 ? '22px' : '28px')
        : '24px',
      fontWeight: 500,
    },
    subtext: 'Всего',
    subtextStyle: {
      color: '#5e6476',
      fontSize: envIsMobile
        ? (isTablet || isPhablet) ? '12px' : (window.screen.width < 300 ? '14px' : '18px')
        : '12px',
      fontWeight: 400,
    },
    textVerticalAlign: 'middle',
    left: 'center',
    top: envIsMobile
      ? (isTablet || isPhablet) ? '27%' : (window.screen.width < 300 ? '20%' : '30%')
      : 'middle',
  },
  color: CHART_COLORS,
  tooltip: { ...DefaultTooltipConfig, trigger: 'item' },
  dataset: {
    // dimensions: ,
    source: [],
  },
  legend: DefaultLegendConfig,
  /* grid: {
    left: '3%',
    right: '4%',
    bottom: '20%',
    top: '5px',
    containLabel: false,
  }, */
  series: {
    type: 'pie',
    radius: envIsMobile
      ? (isTablet || isPhablet) ? ['40%', '60%'] : ['50%', '80%']
      : ['80%', '90%'],
    top: envIsMobile
      ? (isTablet || isPhablet) ? '-38%' : (window.screen.width < 300 ? '-50%' : '-32%')
      : 0,
    left: '10%',
    right: '10%',
    // height: '100%',
    // bottom: '10%',
    // width: '50%',
    encode: {
      itemName: 'name',
      value: 'value',
      tooltip: 'formatted',
    },
    label: {
      show: false,
    },
    emphasis: {
      itemStyle: {
        shadowBlur: 10,
        shadowOffsetX: 0,
        shadowColor: 'rgba(0, 0, 0, 0.5)',
      },
    },
  },
};

const verticalBarsChart1 = (() => {
  const options = JSON.parse(JSON.stringify(LineChartBasicOptions));
  options.series = getBarChart({
    name: '', id: 'value', stack: null, encode: null,
  });
  const { axisLabel } = options.xAxis[0];
  options.xAxis[0].z = 2;
  options.xAxis[0].zLevel = 2;
  axisLabel.rotate = 90;
  axisLabel.inside = true;
  axisLabel.z = 2;
  axisLabel.fontSize = 16;
  return options;
})();

const horizontalBarsChart1 = (() => {
  const options = JSON.parse(JSON.stringify(LineChartBasicOptions));
  options.grid.left = '15px';
  const { xAxis } = options;
  options.xAxis = options.yAxis;
  options.yAxis = xAxis;
  options.series = getBarChart({
    name: '', id: 'value', stack: null, encode: null,
  });
  const { axisLabel } = options.yAxis[0];
  options.yAxis[0].z = 2;
  options.yAxis[0].zLevel = 2;
  // axisLabel.rotate = 90;
  axisLabel.inside = true;
  axisLabel.z = 2;
  axisLabel.fontSize = 16;
  return options;
})();

export const PIECHART_VARIANTS = {
  pie: pieChart1,
  ring: ringChart1,
  'vertical-bars': verticalBarsChart1,
  'horizontal-bars': horizontalBarsChart1,
} as { [key in ChartPieVariant]: any};

export const getPieChartByVariant = (
  variant: ChartPieVariant,
) => PIECHART_VARIANTS[variant] || PIECHART_VARIANTS[ChartPieVariant.Pie];
